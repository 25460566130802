import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const login = createAsyncThunk(
  "admin/signin",
  async ({ username, password }, thunkAPI) => {
    try {
      const data = await DataService.login(username, password);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const logout = createAsyncThunk(
  "logout",
  async () => {
    const url = `users/logout/superadmin`
    const res = await DataService.logout(url)
    return res.data.data
  });

const initialState = {
  user: '',
  isLoggedIn: false,
  message: '',
  flag: true,
  status: false,
  successMsg: '',
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.status = action.payload.data.status;
      state.successMsg = action.payload.data.message; 
      state.user = action.payload.data.data.username;
      state.message = ""
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = "";
      state.successMsg = "";
      state.status = action.payload.success;
      state.message = action.payload.message;
      state.flag = !state.flag
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
  reducers: {
    resetLoginCreds: (state) => {
      state.status = "";
      state.successMsg = ""; 
      state.user = "";
    },
    
  },
});

export const { resetLoginCreds } = authSlice.actions;
const { reducer } = authSlice;
export default reducer;