import React from 'react'
import mainlogo from '../assets/images/main-logo.svg'
import { Menu } from 'antd';
import menurouts from '../routes/menuroutes'
import { NavLink, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import routs from '../routes/menuroutes';
import { useDispatch } from 'react-redux';
import { updateUserHeaderTitle } from '../slice/commonslice';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const Sidebar = ({open,setOpen}) => {
    let path = useLocation();
    const dispatch = useDispatch()

    const pathArray = path.pathname.split('/')
    useEffect(()=>{
        const getheadertitle = (name)=> {
            let routedata = routs.map(item=>{
                let itempath = item.path.split('/')
                if(item.collapse === false){
                    if(itempath[0]=== name){
                        return item
                    }
                }else{
                    return item.children.find(subitem => subitem.path === name)
                }
            })
            return routedata.find(item => {
                let itempath = item?.path.split('/')
                if(itempath && itempath[0] === name){
                    return item
                }
            })
        }
        const headername = getheadertitle(pathArray[2]);
        headername ? dispatch(updateUserHeaderTitle(headername?.name)) : dispatch(updateUserHeaderTitle(''))

    },[path])

    return (
        <aside className={`sidebar-wrap ${open ? 'open' : 'close'}`}>
            <div className="logo-wrap">
                <img src={mainlogo} alt="ATLAS Logo" />
                <p className='username'>Denzel Philip</p>
                <p className="userrole">Admin</p>
                <button className="double-arrow" onClick={()=> setOpen(!open)}>
                    {
                        open ?
                        <KeyboardDoubleArrowLeftIcon />
                        :
                        <KeyboardDoubleArrowRightIcon />
                    }
                </button>
            </div>
            <div className="menu-wraps">
                <Menu mode="inline" inlineCollapsed={!open} defaultSelectedKeys={[`${pathArray[2]}`]}>
                    {
                        menurouts.map((menuitem,index)=>{
                            return(
                                <>
                                    {
                                        menuitem.show &&
                                        <>
                                        { 
                                            !menuitem.collapse ?
                                                <Menu.Item key={menuitem.path}>
                                                    <NavLink to={menuitem.path} className='menu-item'>
                                                        <img src={ pathArray[2] === menuitem.path ? menuitem.menuiconActive: menuitem.menuicon} alt={menuitem.name} />
                                                        <span className={`${pathArray[2] === menuitem.path ? 'active-menu' : ''}`}>{menuitem.name}</span>
                                                    </NavLink>
                                                </Menu.Item>
                                            :
                                                <Menu.SubMenu
                                                title={
                                                    <div className='menu-item'>
                                                        <img src={ pathArray[2] === menuitem.path ? menuitem.menuiconActive: menuitem.menuicon} alt={menuitem.name} />
                                                        <span>{menuitem.name}</span>
                                                    </div>
                                                }
                                                >
                                                    {
                                                        menuitem.children.map((submenuitem,ind)=>{
                                                            return(
                                                                <Menu.Item key={submenuitem.path} >
                                                                    <NavLink to={submenuitem.path} className='menu-item'>
                                                                        <img src={ pathArray[2] === submenuitem.path ? submenuitem.menuiconActive: submenuitem.menuicon} alt={submenuitem.name} />
                                                                        <span>{submenuitem.name}</span>
                                                                    </NavLink>
                                                                </Menu.Item>
                                                            )
                                                        })
                                                    }
                                                </Menu.SubMenu>
                                        }
                                        </>
                                    }
                                </>
                            )
                        })

                    }
                </Menu>
            </div>
        </aside>
    )
}

export default Sidebar