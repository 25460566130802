import React, { useContext } from 'react'
import {
    Grid,
    FormLabel,
} from '@mui/material';
import { CustomerData } from '../LoanApplication'
import { useSelector } from "react-redux";

function Official() {
    const customerData = useContext(CustomerData);
    return (
        <>
            <Grid container spacing={2} style={{ padding: '40px 100px' }}>
                <Grid item xs={4}>
                    <span className='stepper-label'>Company Name:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData?.userDetails?.companyName}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Company Email:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData?.userDetails?.companyEmail}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Company Type:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData?.userDetails?.companyType}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Employee Type:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData?.userDetails?.employeeType}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Income:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData?.userDetails?.salary}</span>
                </Grid>
            </Grid>
        </>
    )
}

export default Official