import React, { useContext } from 'react'
import {
    Grid,
} from '@mui/material';
import { CustomerData } from '../LoanApplication'
function Bank() {
    const customerData = useContext(CustomerData);
    return (
        <>
            <Grid container spacing={2} style={{ padding: '20px 70px' }}>
                <Grid item xs={4}>
                    <span className='stepper-label'>Bank Name:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData?.bankDetails?.bankName}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Branch:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData?.bankDetails?.branch}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Account Holder Name: </span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData?.bankDetails?.accountHolderName}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Account Number: </span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData?.bankDetails?.accountNo}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>IFSC Code:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData?.bankDetails?.ifsc}</span>
                </Grid>
            </Grid>
        </>
    )
}

export default Bank