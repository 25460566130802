import React from 'react'
import { Link } from 'react-router-dom'
import './pagenotfoundstyle.css'

const PageNotFound = () => {
    return (
        <>
            <section className='pagenotfoundparent'>
                <div className='errorpage'>
                    <h2 className='errortitle'>404!</h2>
                    <p className='errormessageone'>Oops! Page not found</p>
                    <p className='errormessagetwo'>The page you are looking for does not exist!</p>
                    <Link to="/admin/loanapplication" className='home'>Home</Link>
                </div>
            </section>
        </>
    )
}

export default PageNotFound