import { http, instance } from '../http-common'
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const get = (url) => {
  return http.get(url)
}

const post = (url, data) => {
  return http.post(url, data)
}

const put = (url, data) => {
  return instance.put(url, data);
}

const login = (username, password) => {
  return axios
    .post(API_URL + "admin/signin", {
      username: username,
      password: password,
    })
    .then((response) => {
      if (response.data.data.accessToken) {
        localStorage.setItem("accessToken", response.data.data.accessToken);
        localStorage.setItem("refreshToken", response.data.data.refreshToken);
      }
      return response;
    });
};

const forgotPassword = (url, data) => {
  return axios.post(API_URL + url, data)
}

const logout = (url) => {
  return http.post(url);
}

const DataService = {
  get,
  post,
  put,
  login,
  logout,
  forgotPassword
};

export default DataService;