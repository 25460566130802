import React, { useEffect, useState } from "react";
import useSharedClass from "../Sharedfeatures/SharedClasses";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE } from "../../utils/variable";
import useTable from "../Sharedfeatures/UseTable";
import PageHeader from "../Sharedfeatures/PageHeader";
import { TXT_CUSTOMER_SUPPORT, TXT_HOME } from "../../utils/header";
import ProgressSpinner from "../common/ProgressSpinner";
import HeadCells from "../common/HeadCells";
import { getCustomerSupportList, acceptIncomingToken, rejectIncomingToken, resetStates } from "../../slice/customerSupport";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from 'antd';
import moment from "moment";
import { IconButton, Snackbar, Stack, Tooltip, TableBody, TableCell, TableRow, createTheme, ThemeProvider } from "@mui/material";
import { useSnackbar } from "../common/useSnackbar";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { openModal, closeModal, openDialog, closeDialog } from "../../slice/modal";
import ChatModal from "./ChatModal";
import EastIcon from '@mui/icons-material/East';
import './customerselectdropdown.css'
import DialogCustomerSupport from "./DialogCustomerSupport";
import io from "socket.io-client";

const CustomerSupport = () => {

  //const [socket, setSocket] = useState(io.connect('https://dev.nbfcb2c.contabo2.mtlstaging.com'))

  const { RangePicker } = DatePicker;
  const sharedClasses = useSharedClass();
  const dispatch = useDispatch();
  const [showPageNtn, setShowPageNtn] = useState(false);
  const [select, setSelect] = useState("");
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const snackBar = useSnackbar();
  const [modalName, setModalName] = useState('');
  const [modalDetails, setModalDetails] = useState('');
  const [modalToken, setModalToken] = useState('')

  //Dialog Box Items
  const [dialogText, setDialogText] = useState("");
  const [selectedToken, setSelectedToken] = useState();
  const [statusText, setStatusText] = useState('')

  const { isModalOpen, isDialogOpen } = useSelector((store) => store.modal)
  const { customerSupportList, totalCount, isLoading, success, message, token } = useSelector((store) => store.customerSupport);
  const { TblContainer, TblHead, TblPagination, pageNo, slNo } = useTable(customerSupportList, HeadCells.customerSupport, totalCount);

  const acceptText = "If you accept this chat you would be able to send and receive messages from this user. Are you sure you want to accept this chat?";
  const rejectText = "If you reject this chat you won't be able to send and receive messages from this user. Are you sure you want to reject this chat?";

  useEffect(() => {
    
    const data = {
      pageNum: pageNo,
      searchQry: select == 'All' ? '' : select,
      startDate,
      endDate
    };
    dispatch(getCustomerSupportList(data));
    setShowPageNtn(true);
  }, [dispatch, pageNo, select, startDate, endDate]);

  useEffect(() => {
    if (success && message) {
      snackBar.openSnackbar(message);
      dispatch(resetStates())
      dispatch(closeDialog())
    }
  }, [success, message, token])


  const handleCreateNewItem = (e) => {
    setSelect(e.target.value);
  };

  //Chat functions
  const handleDialogOpenAcc = (token) => {
    setDialogText(acceptText)
    setStatusText('Accept')
    dispatch(openDialog())
    setSelectedToken(token)
  }

  const handleDialogOpenRej = (token) => {
    setDialogText(rejectText)
    setStatusText('Reject')
    dispatch(openDialog())
    setSelectedToken(token)
  }

  const handleDialogClose = () => {
    dispatch(closeDialog())
  }

  const handleChatAccept = () => {
    dispatch(acceptIncomingToken(selectedToken))
  }

  const handleChatReject = () => {
    dispatch(rejectIncomingToken(selectedToken))
  }

  const handleOpenChat = (item) => {
    // dispatch(openModal())
    // setModalName(item.username)
    // setModalDetails(item.message)
    // setModalToken(item.token)
  }

  const handleCloseChat = () => {
    dispatch(closeModal())
  }

  let serialNo = PAGE_SIZE * slNo;

  const dateFormat = "MM-DD-YYYY"

  const selectItems = [
    { key: '1', value: 'All', label: 'All' },
    { key: '2', value: 'New', label: 'New' },
    { key: '3', value: 'Accepted', label: 'Accepted' },
    { key: '4', value: 'Rejected', label: 'Rejected' },
    { key: '5', value: 'Completed', label: 'Completed' }
  ]

  const theme = createTheme({
    palette: {
      error: {
        main: '#fc0303'
      },
      secondary: {
        main: '#03fc47 !important'
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <div className={sharedClasses.mainDiv}>
        <div className={sharedClasses.pageContent}>
          <PageHeader
            header={TXT_CUSTOMER_SUPPORT}
            subheader={`${TXT_HOME}/${TXT_CUSTOMER_SUPPORT}`}
          />
          <article className={sharedClasses.searchAddParent}>
            <div className={sharedClasses.lpsearchBar}>

              {/* <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={select}
                    autoWidth={false}
                    label="Status"
                    onChange={handleCreateNewItem}
                    className={sharedClasses.lpsearchTextBar}
                  >
                    {
                      selectItems.map(item => (
                        <MenuItem key={item.key} value={item.value}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Box> */}

              <div className="selectdiv">
                <select
                  name="select"
                  value={select}
                  onChange={handleCreateNewItem}
                  className={sharedClasses.lpselectDropdownBar}
                >
                  {
                    selectItems.map(item => (
                      <option key={item.key} value={item.value}>{item.label}</option>
                    ))
                  }
                </select>
              </div>

            </div>
            <RangePicker 
              placeholder={["From MMDDYYYY", "To MMDDYYYY"]}
              format={dateFormat}
              className={sharedClasses.datePicker}
              onChange={(date, dateString) => {
                setStartDate(dateString[0])
                setEndDate(dateString[1])
              }}
              separator={<EastIcon style={{ color: '#bfbfbf' }} />}
            />
          </article>

          <section className={sharedClasses.mainSection}>
            <TblContainer>
              <TblHead />
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8} align='center'>
                      <ProgressSpinner />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {
                    (customerSupportList.length !== 0) ?
                      (
                        customerSupportList &&
                        customerSupportList.map((item) => {
                          serialNo++;
                          return (
                            <TableRow key={item.id}>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {serialNo}
                              </TableCell>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {item.token}
                              </TableCell>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {item.username}
                              </TableCell>
                              <TableCell align='left' className={sharedClasses.tableCell} style={{ paddingLeft: '50px' }}>
                                {item.supportFor.charAt(0).toUpperCase() + item.supportFor.slice(1)}
                              </TableCell>
                              <TableCell align='left' className={sharedClasses.tableCell} style={{ paddingLeft: '50px' }}>
                                {item.message.charAt(0).toUpperCase() + item.message.slice(1)}
                              </TableCell>
                              <TableCell align='left' className={sharedClasses.tableCell}>
                                {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                              </TableCell>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {moment(item.createdAt).format('DD MMMM YYYY')}
                              </TableCell>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {
                                  item.status == 'Accepted' ? (

                                    <Tooltip title="View Chat">
                                      <IconButton onClick={() => handleOpenChat(item)}>
                                        <RemoveRedEyeIcon fontSize='medium' sx={{ color: '#01295F' }} />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      <Stack direction="row" spacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Tooltip title={item.status === 'New' ? "Accept" : ""}>
                                          <IconButton onClick={item.status === 'New' ? () => handleDialogOpenAcc(item.token) : () => { }}>
                                            <DoneIcon fontSize='medium' color={item.status === 'New' ? 'secondary' : ''} />
                                          </IconButton>
                                        </Tooltip>

                                        <Tooltip title={item.status === 'New' ? "Reject" : ""}>
                                          <IconButton onClick={item.status === 'New' ? () => handleDialogOpenRej(item.token) : () => { }}>
                                            <CloseIcon fontSize='medium' color={item.status === 'New' ? 'error' : ''} />
                                          </IconButton>
                                        </Tooltip>
                                      </Stack>
                                    </>
                                  )
                                }
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8} align='center' className={sharedClasses.tableCell}>No Data to Show</TableCell>
                        </TableRow>
                      )
                  }
                </TableBody>
              )}
            </TblContainer>
            {/* {
              isModalOpen ? <ChatModal open={isModalOpen} handleClose={handleCloseChat} modalName={modalName} modalDetails={modalDetails} roomId={modalToken} socket={socket} /> : ''
            } */}
            {
              isDialogOpen ? <DialogCustomerSupport open={isDialogOpen} handleClose={handleDialogClose} handleAccept={handleChatAccept} handleReject={handleChatReject} bodyText={dialogText} statusText={statusText} /> : ''
            }
            {showPageNtn ? <TblPagination /> : ""}
          </section>
        </div>
        <Snackbar {...snackBar} />
      </div>
    </ThemeProvider>
  );
};

export default CustomerSupport;



