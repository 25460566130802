import React from 'react'
import useSharedClasses from '../Sharedfeatures/SharedClasses'
import { Link } from 'react-router-dom'
import { Tooltip, IconButton } from '@material-ui/core'

const PageHeader = ({ header, subheader, icon, link }) => {
    const sharedClasses = useSharedClasses();

    return (
        <div className={sharedClasses.headerOutlay}>

            {
                icon ? (
                    <div className={sharedClasses.backButton}>
                        <Link to={link}>
                            <Tooltip title="Go Back">
                                <IconButton>
                                    {icon}
                                </IconButton>
                            </Tooltip>
                        </Link>
                    </div>
                ) : ''
            }

            {/* <h2 className={sharedClasses.mainHeading}>
                {header}
            </h2> */}
            <h4 className={sharedClasses.subHead}>
                {subheader}
            </h4>
        </div>
    )
}

export default PageHeader