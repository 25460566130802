import React, { useEffect, useState } from "react";
import {
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Button,
  TextField,
  IconButton,
} from "@material-ui/core";
import useSharedClass from "../Sharedfeatures/SharedClasses";
import { SearchOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE } from "../../utils/variable";
import useTable from "../Sharedfeatures/UseTable";
import PageHeader from "../Sharedfeatures/PageHeader";
import { TXT_REPAYMENT, TXT_HOME } from "../../utils/header";
import ProgressSpinner from "../common/ProgressSpinner";
import HeadCells from "../common/HeadCells";
import { getRepaymentsList } from "../../slice/repayments";
import { openModal, closeModal } from "../../slice/modal";
import moment from 'moment';
import RepaymentDetailsModal from "./RepaymentDetailsModal";


const Repayment = () => {

  const sharedClasses = useSharedClass();
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [showPageNtn, setShowPageNtn] = useState(false);
  const { repaymentsList, totalCount, isLoading, repaymentDetails } = useSelector((store) => store.repayments);
  const { isModalOpen } = useSelector((store) => store.modal)
  const { TblContainer, TblHead, TblPagination, pageNo, slNo } = useTable(
    repaymentsList,
    HeadCells.repaymentHead,
    totalCount
  );


  useEffect(() => {
    const data = {
      pageNum: pageNo,
      searchQry: search,
    };
    dispatch(getRepaymentsList(data));
    setShowPageNtn(true);
  }, [dispatch, pageNo, search]);

  let serialNo = PAGE_SIZE * slNo;

  const handleOpenModal = (applicationNo) => {
    // dispatch(viewRepaymentListDetails(applicationNo))
    dispatch(openModal())
  }

  const handleCloseModal = () => {
    dispatch(closeModal())
    // dispatch(resetRepaymentDetails())
  }

  return (
    <>
      <div className={sharedClasses.mainDiv}>
        <div className={sharedClasses.pageContent}>
          <PageHeader
            header={TXT_REPAYMENT}
            subheader={`${TXT_HOME}/${TXT_REPAYMENT}`}
          />
          <article className={sharedClasses.searchAddParent}>
            <div className={sharedClasses.lpsearchBar}>
              <TextField
                size="small"
                className={sharedClasses.lpsearchTextBar}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                id="standard-bare"
                variant="outlined"
                autoComplete="off"
                placeholder="Search By Application No"
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchOutlined
                        className={sharedClasses.searchIconColour}
                      />
                    </IconButton>
                  ),
                }}
              />
            </div>

          </article>
          <section className={sharedClasses.mainSection}>
            <TblContainer>
              <TblHead />
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8} align='center'>
                      <ProgressSpinner />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {
                    (repaymentsList.length !== 0) ?
                      (
                        repaymentsList &&
                        repaymentsList.map((item, key) => {
                          serialNo++;
                          return (
                            <TableRow key={key.toString()}>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {serialNo}
                              </TableCell>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {item.loanNo}
                              </TableCell>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {item.name}
                              </TableCell>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {item.lastRepayedAmount}
                              </TableCell>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {item.principalAmount}
                              </TableCell>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {item.outstandingBalance}
                              </TableCell>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {moment(item.repaymentDate).format('DD MMMM YYYY')}
                              </TableCell>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {/* <Button variant="outlined" color="primary"
                                // onClick={() => handleOpenModal(item.applicationNo)}
                                >
                                  Show
                                </Button> */}
                                {item.totalRepaymentExpected}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8} align='center' className={sharedClasses.tableCell}>No Data to Show</TableCell>
                        </TableRow>
                      )
                  }
                </TableBody>
              )}
            </TblContainer>
            {showPageNtn ? <TblPagination /> : ""}
          </section>
        </div>
      </div>

      {
        isModalOpen ? <RepaymentDetailsModal open={isModalOpen}
          handleClose={handleCloseModal}
        // data={repaymentDetails} 
        /> : ''
      }

    </>
  );
};

export default Repayment;
