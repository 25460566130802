import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getNotifications = createAsyncThunk(
    "get-notifications",
    async (thunkAPI) => {
        try {
            const url = `notification/admin-view`
            const res = await DataService.get(url);
            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    notificationsList: [],
    notificationCount: 0
}

const notificationSlice = createSlice({
    name: "notifications",
    initialState,
    extraReducers: {
        [getNotifications.fulfilled]: (state, action) => {
            state.notificationsList = action.payload.result
            state.notificationCount = action.payload.unSeen
        },
    }
})

export default notificationSlice.reducer