import { Grid } from '@mui/material'
import React, { useContext } from 'react'
import { CustomerData } from '../LoanApplication'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

const IdentityVerification = () => {

    const customerData = useContext(CustomerData);

    const faceVerification = customerData && customerData.application && customerData.application.facematchVerification
    const panVerification = customerData && customerData.application && customerData.application.panCardVerification
    const kycVerification = customerData && customerData.application && customerData.application.ekyc
    const incomeVerification = customerData && customerData.application && customerData.application.incomeVerification

    return (
        <>
            <Grid container spacing={2} style={{ padding: '20px 70px' }}>

                <Grid item xs={4}>
                    <span className='stepper-label'>KYC:</span>
                </Grid>
                <Grid item xs={8}>
                    <div>
                    {
                        kycVerification ? <DoneIcon fontSize='medium' color='success' /> : <CloseIcon fontSize='medium' color='error' />
                    }
                    </div>
                </Grid>

                <Grid item xs={4}>
                    <span className='stepper-label'>PAN:</span>
                </Grid>
                <Grid item xs={8}>
                    <div>
                    {
                        panVerification ? <DoneIcon fontSize='medium' color='success' /> : <CloseIcon fontSize='medium' color='error' />
                    }
                    </div>
                </Grid>

                <Grid item xs={4}>
                    <span className='stepper-label'>Face Match:</span>
                </Grid>
                <Grid item xs={8}>
                    <div>
                    {
                        faceVerification ? <DoneIcon fontSize='medium' color='success' /> : <CloseIcon fontSize='medium' color='error' />
                    }
                    </div>
                </Grid>

                <Grid item xs={4}>
                    <span className='stepper-label'>Income:</span>
                </Grid>
                <Grid item xs={8}>
                    <div>
                    {
                        incomeVerification ? <DoneIcon fontSize='medium' color='success' /> : <CloseIcon fontSize='medium' color='error' />
                    }
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default IdentityVerification