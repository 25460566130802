import React, { useState } from 'react'
import './stepper.css'
import ReviewLoanAppli from './ReviewLoanAppli'
import { Modal, Box } from '@material-ui/core'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    // height: 700,
    minHeight: '80vh',
    bgcolor: 'background.paper',
    padding: '20px 0 50px 0',
    borderRadius: '20px'
};

const Popups = (props) => {
    const [open, setOpen] = useState(true);
    const handleClose = () => setOpen(false);
    return (
        <>
            <Modal open={open}>
                <Box sx={style}>
                    <ReviewLoanAppli data={props} />
                </Box>
            </Modal>
        </>
    )
}

export default Popups
