import React, { useEffect, useState } from "react";
import { Paper, TableBody, TableRow, TableCell, IconButton, Toolbar, TextField } from "@material-ui/core";
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import { TXT_CUSTOMER, TXT_HOME } from "../../utils/header";
import { useDispatch, useSelector } from "react-redux";
import useTable from "../Sharedfeatures/UseTable";
import HeadCells from "../common/HeadCells";
import PageHeader from "../Sharedfeatures/PageHeader";
import { getCustomersList } from '../../slice/customerslists'
import { SearchOutlined } from "@material-ui/icons";
import { PAGE_SIZE } from "../../utils/variable";
import ProgressSpinner from "../common/ProgressSpinner";
import { ConnectedTvOutlined } from "@mui/icons-material";
import moment from 'moment';
import { differenceInYears, parse } from "date-fns"
import { DatePicker } from 'antd';
import EastIcon from '@mui/icons-material/East';

const CustomersList = () => {

    const { RangePicker } = DatePicker;

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const sharedClasses = useSharedClasses();
    const dispatch = useDispatch()
    const { isLoading, customersList, totalCount } = useSelector((store) => store.customerList)

    const { TblContainer, TblHead, TblPagination, pageNo, slNo } = useTable(
        customersList,
        HeadCells.customersHead,
        totalCount
    );
    const [search, setSearch] = useState("")
    const [showPageNtn, setShowPageNtn] = useState(false);
    // const moment = require("moment");

    const calculateAge = (dob) => {
        const date = parse(dob, "dd/MM/yyyy", new Date())
        const age = differenceInYears(new Date(), date)
        return age ? age : '-'
    }

    //Amount, tenure in last stepper from different api.

    useEffect(() => {
        const data = {
            pageNum: pageNo,
            searchQry: search,
            startDate,
            endDate
        };
        dispatch(getCustomersList(data))
        setShowPageNtn(true);
    }, [dispatch, pageNo, search, startDate, endDate])


    let serialNo = PAGE_SIZE * slNo;

    const dateFormat = "MM-DD-YYYY"

    return (
        <>
            <div className={sharedClasses.mainDiv}>
                <div className={sharedClasses.pageContent}>
                    <PageHeader
                        header={TXT_CUSTOMER}
                        subheader={`${TXT_HOME}/${TXT_CUSTOMER}`}
                    />

                    <article className={sharedClasses.searchAddParent}>
                        <div className={sharedClasses.lpsearchBar}>
                            <TextField
                                size="small"
                                className={sharedClasses.lpsearchTextBar}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                id="standard-bare"
                                variant="outlined"
                                autoComplete="off"
                                placeholder="Search By Name"
                                InputLabelProps={{ shrink: false }}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton>
                                            <SearchOutlined
                                                className={sharedClasses.searchIconColour}
                                            />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </div>
                        <RangePicker
                            placeholder={["From MMDDYYYY", "To MMDDYYYY"]}
                            format={dateFormat}
                            className={sharedClasses.datePicker}
                            onChange={(date, dateString) => {
                                setStartDate(dateString[0])
                                setEndDate(dateString[1])
                            }}
                            separator={<EastIcon style={{ color: '#bfbfbf' }} />}
                        />
                    </article>

                    <section className={sharedClasses.mainSection}>
                        <TblContainer>
                            <TblHead />
                            {
                                isLoading ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={10} align='center'>
                                                <ProgressSpinner />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {
                                            (customersList.length !== 0) ? (
                                                customersList &&
                                                customersList.map((item, key) => {
                                                    serialNo++;
                                                    key++;
                                                    return (
                                                        <TableRow key={key.toString()}>
                                                            <TableCell className={sharedClasses.tableCellOne}>
                                                                {serialNo}
                                                            </TableCell>
                                                            <TableCell className={sharedClasses.tableCell}>
                                                                {item.firstName} {item.middleName} {item.lastName}
                                                            </TableCell>
                                                            <TableCell className={sharedClasses.tableCellOne}>
                                                                {calculateAge(item.dob)}
                                                            </TableCell>
                                                            <TableCell className={sharedClasses.tableCellOne}>
                                                                {item.salary}
                                                            </TableCell>
                                                            <TableCell className={sharedClasses.tableCellOne}>
                                                                {item && item.bank && item.bank.accountNo}
                                                            </TableCell>
                                                            <TableCell className={sharedClasses.tableCellOne}>
                                                                {item.loans}
                                                            </TableCell>
                                                            <TableCell className={sharedClasses.tableCellOne}>
                                                                {moment(item.createdAt).format('DD MMMM YYYY')}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={10} align='center' className={sharedClasses.tableCell}>No Data to Show</TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                )
                            }

                        </TblContainer>
                        {showPageNtn ? <TblPagination /> : ""}
                    </section>
                </div>
            </div>
        </>
    );
};

export default CustomersList;
