import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
const initialState = {
    updateUserHeaderTitle:'',
}
const commonSlice = createSlice({
    name : 'commonslice',
    initialState,
    reducers: {
        updateUserHeaderTitle: (state, {payload})=>{
            state.updateUserHeaderTitle = payload
        },
    },
    extraReducers:{
    }
})
export const { updateUserHeaderTitle } = commonSlice.actions
export const getupdateuserheadertitle = (state) => state.commonapi.updateUserHeaderTitle;
export default commonSlice.reducer
