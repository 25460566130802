import React, { createContext, useState, useEffect } from "react";
import { TableBody, TableRow, TableCell, Toolbar, TextField, IconButton } from "@material-ui/core";
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import { Link } from "react-router-dom";
import { getLoanApplicationList, getLoanApplicationDetails, reset_message, resetStatus } from "../../slice/loanapplication";
import { TXT_LOAN_APPLICATION, TXT_HOME } from "../../utils/header";
import { PAGE_SIZE } from "../../utils/variable";
import { useDispatch, useSelector } from "react-redux";
import useTable from "../Sharedfeatures/UseTable";
import Popups from "./Stepper/Popup";
import HeadCells from "../common/HeadCells";
import ProgressSpinner from "../common/ProgressSpinner";
import PageHeader from "../Sharedfeatures/PageHeader";
import { SearchOutlined } from "@material-ui/icons";
import Button from "../controls/Button";
import moment from 'moment';
import { DatePicker } from 'antd';
import EastIcon from '@mui/icons-material/East';

const CustomerData = createContext();

const LoanApplication = () => {

  const { RangePicker } = DatePicker;
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [showPageNtn, setShowPageNtn] = useState(false);
  const [search, setSearch] = useState("");

  const {
    loanApplications,
    applicDetails,
    totalCount,
    success,
    message,
    loading,
    modalStatus,
  } = useSelector((state) => state.loanApplication); // applicDetails is used to use useContext

  const { TblContainer, TblHead, TblPagination, pageNo, slNo } = useTable(
    loanApplications,
    HeadCells.loanApplicationHead,
    totalCount
  );
  const sharedClasses = useSharedClasses();
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      pageNum: pageNo,
      searchQry: search,
      startDate,
      endDate
    };
    dispatch(getLoanApplicationList(data)); //to show loan applications list
    setShowPageNtn(true)
    dispatch(reset_message());
  }, [dispatch, pageNo, search, startDate, endDate]);

  const handleClose = () => {
    dispatch(resetStatus(false));
  };
  const handleShowDetails = (item) => {
    dispatch(reset_message());
    dispatch(resetStatus(true));
    dispatch(getLoanApplicationDetails(item.userId)); // to show the details of loan application in stepper
  };

  let serialNo = PAGE_SIZE * slNo;

  const dateFormat = "MM-DD-YYYY"

  return (
    <>
      <div className={sharedClasses.mainDiv}>
        <div className={sharedClasses.pageContent}>
          <PageHeader
            header={TXT_LOAN_APPLICATION}
            subheader={`${TXT_HOME}/${TXT_LOAN_APPLICATION}`}
            a
          />

          <article className={sharedClasses.searchAddParent}>
            <div className={sharedClasses.lpsearchBar}>
              <TextField
                size="small"
                className={sharedClasses.lpsearchTextBar}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                id="standard-bare"
                variant="outlined"
                autoComplete="off"
                placeholder="Search By Application No"
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchOutlined
                        className={sharedClasses.searchIconColour}
                      />
                    </IconButton>
                  ),
                }}
              />
            </div>
            <RangePicker
              placeholder={["From MMDDYYYY", "To MMDDYYYY"]}
              format={dateFormat}
              className={sharedClasses.datePicker}
              onChange={(date, dateString) => {
                setStartDate(dateString[0])
                setEndDate(dateString[1])
              }}
              separator={<EastIcon style={{ color: '#bfbfbf' }} />}
            />
          </article>

          <section className={sharedClasses.mainSection}>
            <TblContainer>
              <TblHead />
              {loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      <ProgressSpinner />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {loanApplications.length !== 0 ? (
                    loanApplications.map((item, key) => {
                      serialNo++;
                      key++;
                      return (
                        <TableRow key={item.id.toString()}>
                          <TableCell>{serialNo}</TableCell>
                          <TableCell align="center" className={sharedClasses.tableCell}>
                            {item.user.firstName} {item.user.lastName}
                          </TableCell>
                          <TableCell align="center" className={sharedClasses.tableCell}>
                            {item.applicationNumber}
                          </TableCell>
                          <TableCell align="center" className={sharedClasses.tableCell}>
                            {item.user.phone}
                          </TableCell>
                          {/* <TableCell className={sharedClasses.tableCell}>
                            {item.user.email}
                          </TableCell> */}
                          <TableCell align="center" className={sharedClasses.tableCell}>
                            {item.user.companyName}
                          </TableCell>
                          <TableCell align="center" className={sharedClasses.tableCell}>
                            {moment(item.createdAt).format('DD MMMM YYYY')}
                          </TableCell>
                          <TableCell align="center" className={sharedClasses.tableCell}>
                            {item.loanAmount}
                          </TableCell>
                          <TableCell align="center"
                            className={sharedClasses.tableCell}
                            onClick={() => handleShowDetails(item)}
                          >
                            <Link to="#">
                              <Button variant="outlined" text="Show" size="medium" sx={{ backgroundColor: '#01295F' }} />
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={10}
                        align="center"
                        className={sharedClasses.tableCell}
                      >
                        No Data to Show
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </TblContainer>
            {showPageNtn ? <TblPagination /> : ""}
          </section>
          <CustomerData.Provider value={applicDetails}>
            {modalStatus === true ? (
              <Popups
                data={applicDetails}
                handleClick={handleShowDetails}
                handleClose={handleClose}
              />
            ) : (
              " "
            )}
          </CustomerData.Provider>
        </div>
      </div>
    </>
  );
};
export { CustomerData };
export default LoanApplication;
