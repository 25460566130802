import { IoIosArrowBack } from 'react-icons/io'

export const TXT_LOAN_PRODUCTS = 'Loan Products';
export const TXT_ADD_LOAN = 'Add New Loan';
export const TXT_PRE_APPROVED = 'Pre Approved Loan Offers';
export const TXT_LOAN_APPLICATION = 'Loan Applications';
export const TXT_VIEW_LOAN = 'Loan Details';
export const TXT_LOAN_SANCTIONED= 'Loans Sanctioned';
export const TXT_LOAN_ACCOUNTDET= 'Loan Account Details';
export const TXT_LOAN_CREDITREPORTS= 'Credit Reports';
export const TXT_LOAN_DISBURSED = 'Loans Disbursed';
export const TXT_LOAN_STATEMENT = 'Loan Statement';
export const TXT_TRANCHE_DISBURSED = 'Tranche Disbursed';
export const TXT_HOME = 'Home';
export const TXT_REPAYMENT = 'Latest Repayments';
export const TXT_LOAN_GENERATED = 'Loans Generated';
export const TXT_REJ_APPLN = 'Rejected Applications';
export const TXT_CUSTOMER = 'Customers';
export const TXT_CUSTOMER_SUPPORT = 'Customer Support';
export const DASHBOARD = 'Dashboard'
export const TRANCHE_REQUEST = 'Tranche Requests';
export const CREDIT_REPORT = 'Credit Bureau Report';
export const ECAPS_REPORT = 'ECAPS Business Report'

export const BACK_BUTTON = <IoIosArrowBack color='#963460'/>