import React from 'react'
import { Link } from 'react-router-dom'
import TextBox from '../controls/TextBox'
import logo from '../../assets/images/login-page-logo.svg'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from '../Sharedfeatures/useForm'
import { forgotPassword } from '../../slice/forgotpassword'
import { Alert, Button } from '@mui/material'

const initialValues = {
    email: '',
}
const ForgotPassword = () => {
    const [alert, setAlert] = useState(false)
    const dispatch = useDispatch()

    const { message } = useSelector((store) => store.forgotPassword)
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("email" in fieldValues) {
            (temp.email = /$^|.+@.+..+/.test(fieldValues.email) ? "" : "Email is not valid.") || (temp.email = fieldValues.email ? "" : "This field is required.");
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };
    const {
        values,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialValues, true, validate);

    
    const handleSubmit = (e) => {
        e.preventDefault()
        if (validate()) {
            dispatch(forgotPassword(values))
                .then((res) => {
                    setAlert(true)
                    resetForm()
                    const timer = setTimeout(() => {
                        setAlert(false);
                        return () => clearTimeout(timer);
                    }, 3000)
                })
                .catch((err) => {

                })
        }

    }
    return (
        <div className="login-page-wrap">
            <div className="login-wrap">
                <div className="img-wrap">
                    <img src={logo} alt="Atlas Logo" />
                </div>
                <div className="login-form-wrap">
                    <div className="title-wrap">
                        <span>Admin - Forgot Password</span>
                    </div>
                    {
                        alert && (
                            <Alert severity="info" sx={{ mt: 1 }}>
                                {message}
                            </Alert>
                        )
                    }
                    <form autoComplete="off" onSubmit={handleSubmit}className='login-form'>
                        <TextBox
                            name="email"
                            label="Enter Your Email"
                            onChange={handleInputChange}
                            value={values.email}
                            error={errors.email}
                            color="secondary"
                            sx={{color: 'rgba(47, 38, 97, 0.75)',
                                fontFamily: 'Poppins',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: 'normal',
                                width: '100%',
                                marginTop:'10px'
                            }}
                            variant="standard"
                        />
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className="login-button"
                        >
                        Submit
                        </Button>
                        <div className="forgot-link">
                            <Link to="/auth/login" className='forgotlink'>
                                <span >Just remembered your password? Go Back</span>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword