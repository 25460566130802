import React from "react";
import Logo from "../../b2clogo.svg";
import { Avatar } from '@mui/material';
import Header from "../Header/Header";
import "antd/dist/antd.css";
import routes from '../../Routes';
import { useLocation } from "react-router-dom";
import { Menu } from "antd";
import { SidebarHeader, SidebarContent } from "react-pro-sidebar";
import { NavLink } from "react-router-dom";
import "./Navbar.css";

const { SubMenu } = Menu;

const Navbar = () => {
  let location = useLocation();

  const createLinks = (routes) => {
    return (<Menu theme="dark" mode="inline">
      {routes.map((route, key) =>
        route.collapse === true && route.key !== 'loangenerated' ?
          (
            <Menu.Item key={route.name} icon={route.icon} title={route.name}
              className={(location.pathname === route.layout + route.path) ? 'activeMenuItem' : 'menuItem'}
            >
              <NavLink to={route.layout + route.path}
                className={({ isActive }) => (isActive ? 'link active' : 'link')}
              >{route.name}</NavLink>
            </Menu.Item>
          ) : route.collapse === true && route.key === 'loangenerated' ?
            (<SubMenu key={route.name} icon={route.icon} title={route.name} className="link">
              {route && route.views.map((subItem) =>
              (
                <Menu.Item key={subItem.name} icon={subItem.icon} title={subItem.name} style={{ marginTop: '5px', marginLeft: '20px' }}>
                  <NavLink to={subItem.layout + subItem.path} className={({ isActive }) => (isActive ? 'link active' : 'link')}>
                    {subItem.name}
                  </NavLink>
                </Menu.Item>)
              )}
            </SubMenu>)
            : null
      )}
    </Menu>)
  };

  return (
    <>
      <div id="sidebar">
        <div >
          <SidebarHeader>
            <Header />
            <div className="logotext">
              <div className="avatar">
                <Avatar
                  sx={{
                    width: "100px !important",
                    height: "100px !important",
                    bgcolor: "#FFFFFF",
                  }}
                >
                  <img src={Logo} alt="logo" width="50px" height="50px" />
                </Avatar>
              </div>
              <p id="ecaps">ECAPS</p>
              <p id="atlas">NBFC B2C</p>
              <p id="admin">ADMIN</p>
            </div>
          </SidebarHeader>
        </div>
        <SidebarContent>{createLinks(routes)}</SidebarContent>
      </div>
    </>
  );
};

export default Navbar;
