import React from 'react'
import { Box, Modal, Grid, IconButton, Typography, } from '@material-ui/core';
import CloseIcon from "@mui/icons-material/Close";
import Button from '@mui/material/Button';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import useStyles from './DisbursedStyles';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    minHeight: 450,
    bgcolor: 'background.paper',
    borderRadius: '20px',
    boxShadow: 24
};

const DisbursedDetailsModal = ({ open, handleClose, data }) => {

    const classes = useStyles();
    console.log("MODAL PASSED DATA", data)

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>

                    <Box className={classes.headerParent}>
                        <IconButton className={classes.closeButton} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>

                        <Typography variant="h5" color="#01295F" align="center" style={{ paddingTop: '15px', fontWeight: "bold" }}>
                            Disbursed Loan Details
                        </Typography>
                    </Box>

                    {
                        Object.entries(data).length == 0 ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: 390 }}>
                                <ArrowCircleDownIcon />
                                <p style={{ fontWeight: 'bold' }}>Loading Data....</p>
                            </Box>

                        ) : (
                            <>
                                <Grid container spacing={1} style={{ padding: '20px 100px' }}>
                                    <Grid item xs={4}>
                                        <span className='stepper-label'>Transaction id:</span>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <span className='stepper-label'>
                                            {data.id}
                                        </span>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <span className='stepper-label'>To:</span>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {data.fund_account_id}
                                    </Grid>

                                    <Grid item xs={4}>
                                        <span className='stepper-label'>From:</span>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <span className='stepper-label'>E-caps</span>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <span className='stepper-label'>Description:</span>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <span className='stepper-label'>
                                            {data.narration}
                                        </span>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <span className='stepper-label'>Amount:</span>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {data.amount}
                                    </Grid>

                                    <Grid item xs={4}>
                                        <span className='stepper-label'>Fees:</span>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {data.fees}
                                    </Grid>

                                    <Grid item xs={4}>
                                        <span className='stepper-label'>Tax:</span>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {data.tax}
                                    </Grid>

                                    <Grid item xs={4}>
                                        <span className='stepper-label'>Status:</span>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {data.status}
                                    </Grid>

                                </Grid>
                                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button variant="contained" onClick={handleClose} style={{ backgroundColor: '#01295F', color: '#fff', fontWeight: 'bold' }}>Ok</Button>
                                </Box>
                            </>
                        )
                    }
                </Box>
            </Modal>
        </>
    )
}

export default DisbursedDetailsModal