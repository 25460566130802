import React from 'react'
import './headernew.css'
import { BellOutlined, PoweroffOutlined, SearchOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { getNotifications } from '../../slice/header'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { getupdateuserheadertitle } from '../../slice/commonslice'
import NotificationsModal from './NotificationsModal'
const HeaderNew = () => {
    const dispatch = useDispatch()
    const [notifyOpen, setNotifyOpen] = useState(false)
    const pageTtile = useSelector(getupdateuserheadertitle)

    const handleNotifyOpen = () => {
        setNotifyOpen(true)
    // console.log('Open')
    }

    const handleNotifyClose = () => {
        setNotifyOpen(false)
    }

    useEffect(() => {
        dispatch(getNotifications())
    }, [])
    return (
        <header className='header'>
            <div className="title-wrap">
                <div className="title">
                    <span>{pageTtile}</span>
                </div>
                <div className="search-wrap">
                    <Button icon={<SearchOutlined className='search-icon'/>}>Search</Button>
                </div>
            </div>
            <div className="right-buttons-wrap">
                <BellOutlined onClick={handleNotifyOpen}className='right-icons'/>
                <Link to='/auth/logout'>
                    <PoweroffOutlined className='right-icons'/>
                </Link>
            </div>
            {
                notifyOpen ? <NotificationsModal open={notifyOpen} handleClose={handleNotifyClose} /> : ''
            }
        </header>
    )
}

export default HeaderNew