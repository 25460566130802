import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../slice/auth";

const Logout = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(logout())
  //   .unwrap()
  //           .then((res) => {
  //             localStorage.clear();
  //             localStorage.removeItem("accessToken");
  //             const accessToken = localStorage.getItem("accessToken");
  //             if (accessToken === null || accessToken === ' undefined') {
  //               navigate("/auth/login");
  //             }
  //           })
  //           .catch((err) => {
  //           });

  // });

  useEffect(() => {
    setTimeout(() => {
      localStorage.clear()
      navigate("/auth/login")
    }, 1000);
  }, [])

  return (
    <div
      style={{
        textAlign: "center",
        fontWeight: "bolder",
        color: "#01295F",
        fontSize: "20",
      }}
    >
      Logging Out...
    </div>
  );
};

export default Logout;
