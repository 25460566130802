import { Grid, Box } from '@mui/material'
import React, { useContext, useState, useEffect } from 'react'
import Button from '../../controls/Button'
import IncomeVerificationModal from './IncomeVerificationModal'
import { CustomerData } from '../LoanApplication'
import { useDispatch, useSelector } from 'react-redux'
import { getIncomeDetails, getIncomeVerificationLoanDetails } from '../../../slice/loanapplication'
import { openModal, closeModal } from '../../../slice/modal'

const IncomeVerification = () => {

  const dispatch = useDispatch();
  const { isModalOpen } = useSelector((store) => store.modal)
  const { incomeDetails } = useSelector((store) => store.loanApplication)
  const customerData = useContext(CustomerData);
  // console.log("INCOME VERIFICATION CUSTOMER DATA", customerData)
  const applicationId = customerData && customerData.application && customerData.application.id

  useEffect(() => {
    dispatch(getIncomeDetails(applicationId))
  }, [])

  return (
    <Box sx={{ height: '100%' }}>
      <Grid container spacing={1} style={{ padding: '70px' }} alignItems="center">
        <Grid item md={1}></Grid>
        <Grid item md={4}>
          <h3>
            Loan Amount
          </h3>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={6}>
          <h3>
            {customerData && customerData.application && customerData.application.loanAmount}
          </h3>
        </Grid>

        <Grid item md={1}></Grid>
        <Grid item md={4}>
          <h3>
            Loan Tenure
          </h3>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={6}>
          <h3>
            {customerData && customerData.application && customerData.application.tenure}
          </h3>
        </Grid>

        <Grid item md={1}></Grid>
        <Grid item md={4}>
          <h3>
            E-Mandate Registration
          </h3>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={6}>
          <h3>
            {customerData && customerData.application && customerData.application.emandate === true ? "Verified" : "Not Verified"}
          </h3>
        </Grid>

        <Grid item md={1}></Grid>
        <Grid item md={4}>
          <h3>
            E-Sign Status
          </h3>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={6}>
          <h3>
            {customerData && customerData.application && customerData.application.eSignStatus === true ? "Completed" : "Pending"}
          </h3>
        </Grid>

        <Grid item md={1}></Grid>
        <Grid item md={4}>
          <h3>
            Income details of the customer
          </h3>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={6}>
          <Button text="View Income Details" className='incomeDetailsButton' onClick={() => dispatch(openModal())} />
        </Grid>
      </Grid>
      {
        isModalOpen ? <IncomeVerificationModal open={isModalOpen} handleClose={() => dispatch(closeModal())} data={incomeDetails} /> : ''
      }
    </Box>
  )
}

export default IncomeVerification