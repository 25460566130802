import React from 'react'
import './pagelayout.css'
import Sidebar from './Sidebar'
import { useState } from 'react';
import { useEffect } from 'react';
import HeaderNew from '../components/Header/HeaderNew';
import MainContent from './MainContent';
import { useLocation } from 'react-router-dom';

const PageLayout = () => {
    const [open, setOpen] = useState(true);
    let path = useLocation();
    const pathArray = path.pathname.split('/')

    useEffect(()=>{
        function handleResize() {
            if(window.innerWidth < 1080){
                setOpen(false)
            }else{
                setOpen(true)
            }
        }
        window.addEventListener('resize', handleResize)
        window.addEventListener('load', handleResize)
    })
    return (
        <div className="atlas-pagelayout">
            <Sidebar open={open} setOpen={setOpen}/>
            <div className={`content-wrap ${open ? 'open' : 'close'}`}>
                <div className="header-wrap">
                    <HeaderNew/>
                </div>
                <div className="maincontent-wrap" style={{background:`${pathArray.includes('dashboard') ? '#ffffff' : '#F2F7FA'}`}}>
                    <MainContent/>
                </div>
            </div>
        </div>
    )
}
export default PageLayout