import React, { useEffect, useState } from 'react'
import './login.css'
import logo from '../../assets/images/login-page-logo.svg'
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from 'react-redux';
import { login, resetLoginCreds } from "../../slice/auth";
import { styled } from "@mui/system";
import { Button, IconButton } from '@mui/material';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Link, useNavigate } from 'react-router-dom';

const TextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
    // borderBottom: "5px solid #fff"
}));

const Login = () => {
    const [state, setState] = useState({ username: "" });
    const [password, setPassword] = useState(false);
    const [alert, setAlert] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { message, flag, isLoggedIn, status, successMsg, user } = useSelector((state) => state.auth);

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login(state))
    };
    useEffect(() => {
        if (message !== undefined) {
          setAlert(message);
          const timer = setTimeout(() => {
            setAlert(null);
            return () => clearTimeout(timer);
          }, 2000);
        }
    }, [message, flag]);
    
    useEffect(() => {
        if (isLoggedIn && status && successMsg && user) {
          navigate('/admin/customers')
          dispatch(resetLoginCreds())
        }
    }, [isLoggedIn, status, successMsg, user])
    
    return (
        <div className="login-page-wrap">
            <div className="login-wrap">
                <div className="img-wrap">
                    <img src={logo} alt="Atlas Logo" />
                </div>
                <div className="login-form-wrap">
                    <div className="title-wrap">
                        <span>Admin - Log in</span>
                    </div>
                    <ValidatorForm
                        onSubmit={handleSubmit}
                        onError={() => null}
                        className='login-form'
                    >
                        <TextField
                        type="text"
                        id="standard-basic"
                        label="Enter Username"
                        name="username"
                        InputLabelProps={{
                            style: {'color': 'rgba(47, 38, 97, 0.75)',
                                'font-family': 'Poppins',
                                'font-size': '14px',
                                'font-style': 'normal',
                                'font-weight': 500,
                                'line-height': 'normal',
                                'width': '100%',
                                'margin-top':'10px'
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                            <IconButton>
                                <PersonOutlineOutlinedIcon id="userIcon" />
                            </IconButton>
                            ),
                        }}
                        value={state.username || ""}
                        autoComplete="off"
                        variant="standard"
                        onChange={handleChange}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                        />
                        <TextField
                            id="standard-basic"
                            label="Enter Password"
                            className="password"
                            type={password ? "text" : "password"}
                            name="password"
                            value={state.password || ""}
                            autoComplete="off"
                            variant="standard"
                            onChange={handleChange}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            InputLabelProps={{
                                style: {'color': 'rgba(47, 38, 97, 0.75)',
                                    'font-family': 'Poppins',
                                    'font-size': '14px',
                                    'font-style': 'normal',
                                    'font-weight': 500,
                                    'line-height': 'normal',
                                    'width': '100%',
                                    'margin-top':'10px'
                                },
                            }}
                        InputProps={{
                            endAdornment: (
                            password ? (
                                <IconButton onClick={() => setPassword(false)} >
                                <Visibility id="userIcon" />
                                </IconButton>
                            ) : (
                                <IconButton onClick={() => setPassword(true)}>
                                <VisibilityOff id="userIcon" />
                                </IconButton>
                            )
                            ),
                        }}

                        />
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className="login-button"
                        >
                        Log In
                        </Button>
                        <div className="forgot-link">
                            <Link to="/forgotpassword" className='forgotlink'>
                                <span >Forgot password? Click Here</span>
                            </Link>
                        </div>
                    </ValidatorForm>
                </div>
            </div>
        </div>
    )
}

export default Login