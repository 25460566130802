import React from "react";
import { Routes, Route } from "react-router-dom";
import routes from '../Routes';

const AuthLayout = () => {
  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.layout === "/auth") {
        return (
          <Route path={route.path} key={key} element={<route.component />} />
        );
      } else {
        return ''
      }
    })
  }
  return (
    <Routes>{getRoutes(routes)}</Routes>
  )
}

export default AuthLayout;