import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isModalOpen: false,
  isNotifOpen: false,
  isDialogOpen: false
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
    openNotif: (state) => {
      state.isNotifOpen = true;
    },
    closeNotif: (state) => {
      state.isNotifOpen = false;
    },
    openDialog: (state) => {
      state.isDialogOpen = true;
    },
    closeDialog: (state) => {
      state.isDialogOpen = false;
    }
  },
});

export const { openModal, closeModal, openNotif, closeNotif, openDialog, closeDialog } = modalSlice.actions;

export default modalSlice.reducer;