import React, { useContext } from 'react'
import {
    Grid,
} from '@mui/material';
import './stepper.css';
import { CustomerData } from '../LoanApplication'

function Personal() {
    const customerData = useContext(CustomerData);
    return (
        <>
            <Grid container spacing={1} style={{ padding: '20px 100px' }}>
                <Grid item xs={4}>
                    <span className='stepper-label'>Name:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>
                        {customerData && customerData?.userDetails?.firstName} {customerData && customerData?.userDetails?.middleName} {customerData && customerData?.userDetails?.lastName}
                    </span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Date Of Birth:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData?.userDetails?.dob}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Phone:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData?.userDetails?.phone}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Email:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData?.userDetails?.email}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Aadhar:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData?.userDetails?.aadhaarNo}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Pan No:</span>
                </Grid>
                <Grid item xs={8}>
                    <span className='stepper-label'>{customerData && customerData?.userDetails?.panNo}</span>
                </Grid>
                <Grid item xs={4}>
                    <span className='stepper-label'>Address:</span>
                </Grid>
                <Grid item xs={8}>
                    <p className='stepper-label'>{customerData && customerData?.userDetails?.address1}</p>
                    <p className='stepper-label'>{customerData && customerData?.userDetails?.address2}</p>
                </Grid>
            </Grid>
        </>
    )
}

export default Personal