import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DataService from '../services/DataService'

export const getCustomerSupportList = createAsyncThunk(
  'customers-support-list',
  async ({ pageNum, searchQry, startDate, endDate }, thunkAPI) => {
    try {
      const url = `chat/list-support?take=10&keyword=${searchQry}&page=${pageNum}&fromDate=${startDate}&toDate=${endDate}`
      const res = await DataService.get(url)
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
)

export const acceptIncomingToken = createAsyncThunk(
  'accept-incoming-token',
  async (token, thunkAPI) => {
    try {
      const url = `chat/accept-token/${token}`
      const res = await DataService.post(url)
      // return token
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
)

export const rejectIncomingToken = createAsyncThunk(
  'reject-incoming-token',
  async (token, thunkAPI) => {
    try {
      const url = `chat/reject-token/${token}`
      const res = await DataService.post(url)
      return res.data;
      // return token;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
)


const initialState = {
  isLoading: true,
  customerSupportList: [],
  totalCount: '',
  success: '',
  message: '',
  token: ''
};

const customerSupportSlice = createSlice({
  name: 'customerSupport',
  initialState,
  extraReducers: {
    [getCustomerSupportList.pending]: (state) => {
      state.isLoading = true;
    },
    [getCustomerSupportList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.customerSupportList = action.payload.data.result.user
      state.totalCount = action.payload.data.result.dataCount
    },
    [getCustomerSupportList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message
    },
    [acceptIncomingToken.fulfilled]: (state, action) => {
      state.success = action.payload.success;
      state.message = action.payload.message;
      state.token = action.payload.data;
      let token_rec = action.payload.data;
      let queriesList = state.customerSupportList
      let index = queriesList.findIndex(item => item.token == token_rec)
      state.customerSupportList[index].status = 'Accepted';
    },
    [acceptIncomingToken.rejected]: (state, error) => {

    },
    [rejectIncomingToken.fulfilled]: (state, action) => {
      state.success = action.payload.success;
      state.message = action.payload.message;
      state.token = action.payload.data;
      let token_rec = action.payload.data;
      let queriesList = state.customerSupportList
      let index = queriesList.findIndex(item => item.token == token_rec)
      state.customerSupportList[index].status = 'Rejected';
    },
    [rejectIncomingToken.rejected]: (state, error) => {

    },
  },
  reducers: {
    resetStates: (state) => {
      state.success = false;
      state.message = '';
      state.token = '';
    }
  }
})

export const { resetStates } = customerSupportSlice.actions
export default customerSupportSlice.reducer