import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getLoanApplicationList = createAsyncThunk(
  "loanApplicationsList",
  async ({ startDate, endDate, pageNum, searchQry }) => {
    const url =`admin/all-application?keyword=${searchQry}&page=${pageNum}&limit=10&fromDate=${startDate}&toDate=${endDate}`
    const res = await DataService.get(url);
    return res.data
  }
);

export const getLoanApplicationDetails = createAsyncThunk(
  "loanApplicationsDetails",
  async (id) => {
    const url = `/admin/singleuser/${id}`
    const res = await DataService.get(url);
    return res.data
  }
);

export const getIncomeVerificationLoanDetails = createAsyncThunk(
  "incomeVerificationLoanDetails",
  async (id) => {
    const url = `loan/income-details/${id}`
    const res = await DataService.get(url);
    return res.data
  }
);

export const getIncomeDetails = createAsyncThunk(
  "getIncomeDetails",
  async(id) => {
    const url = `/admin/income-details/${id}`
    const res = await DataService.get(url);
    return res.data
  }
)

export const reset_message = createAsyncThunk(
  "resetmessage",
  async () => {
    return;
  }
)

export const resetStatus = createAsyncThunk(
  "resetStatus",
  async (state) => {
    return state;
  }
)

const initialState = {
  loanApplications: [],
  applicDetails: [],
  loanData: [],
  loanProductsList: [],
  clientLoan: [],
  totalCount: '',
  error: '',
  success: "",
  loading: true,
  modalStatus: false,
  cibil_data: "",
  incomeDetails: {}
};

const loanApplicationSlice = createSlice({
  name: "loanApplication",
  initialState,
  extraReducers: {
    [getLoanApplicationList.pending]: (state, action) => {
      state.loading = true;
    },
    [getLoanApplicationList.fulfilled]: (state, action) => {
      state.loanApplications = action.payload?.applications
      state.totalCount = action.payload.dataCount
      state.loading = false
    },
    [getLoanApplicationList.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
    [getLoanApplicationDetails.fulfilled]: (state, action) => {
      state.applicDetails = action.payload
    },
    [getIncomeVerificationLoanDetails.fulfilled]: (state, action) => {
      // ************************************
    },
    [getIncomeDetails.fulfilled]: (state, action) => {
      state.incomeDetails = action.payload.data;
    },
    [reset_message.fulfilled]: (state, action) => {
      state.success = "";
      state.message = "";
      state.applicDetails = "";
    },
    [resetStatus.fulfilled]: (state, action) => {
      state.modalStatus = action.payload;
    }

  },
});

const { reducer } = loanApplicationSlice;

export default reducer;