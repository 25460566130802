import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/DataService";

export const getLoanDisbursedList = createAsyncThunk(
  "disburseList",
  async ({ startDate, endDate, searchQry, pageNum }, thunkAPI) => {
    try {
      const url = `admin/all-disbursed-loans?keyword=${searchQry}&page=${pageNum}&limit=10&fromDate=${startDate}&toDate=${endDate}`
      const res = await DataService.get(url);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const viewLoanDisbursedDetails = createAsyncThunk(
  "viewdisbursedetails",
  async (applicNo, thunkAPI) => {
    try {
      const url = `admin/disbursed-loans/${applicNo}`
      const res = await DataService.post(url);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
)

const initialState = {
  disburseList: [],
  disburseDetails: {},
  totalCount: "",
  isLoading: true,
};

const loanDisbursedSlice = createSlice({
  name: "disburseList",
  initialState,
  extraReducers: {
    [getLoanDisbursedList.pending]: (state) => {
      state.isLoading = true
    },
    [getLoanDisbursedList.fulfilled]: (state, action) => {
      state.disburseList = action.payload.data;
      state.totalCount = action.payload.totalCount;
      state.isLoading = false
    },
    [getLoanDisbursedList.rejected]: (state) => {
      state.isLoading = false
    },
    [viewLoanDisbursedDetails.fulfilled]: (state, action) => {
      console.log("ACT", action)
      state.disburseDetails = action.payload.responseData
    }
  },
  reducers: {
    resetDisburseDetails: (state) => {
      state.disburseDetails = {};
    }
  }
});

export const { resetDisburseDetails } = loanDisbursedSlice.actions;
export default loanDisbursedSlice.reducer
