import React, { useState } from 'react'
import { Alert } from '@mui/material'
import useStyles from './PasswordStyleSheet'
import { useForm } from '../Sharedfeatures/useForm'
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../slice/forgotpassword';
import TextBox from '../controls/TextBox';
import Button from '../controls/Button'
import { logout } from '../../slice/auth'

const initialValues = {
    newPassword: '',
    confirmNewPassword: ''
}

const ResetPassword = () => {

    const styles = useStyles()
    const dispatch = useDispatch()
    const [alert, setAlert] = useState(false)
    const [showPasswordMatchError, setShowPasswordMatchError] = useState(false)
    const { message, success } = useSelector((store) => store.forgotPassword)
    const { id } = useParams()

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("newPassword" in fieldValues)
            temp.newPassword = fieldValues.newPassword ? "" : "This field is required";
        if ("confirmNewPassword" in fieldValues)
            temp.confirmNewPassword = fieldValues.confirmNewPassword ? "" : "This field is required";
        // if ("newPassword" in fieldValues && "confirmNewPassword" in fieldValues && fieldValues.newPassword === fieldValues.confirmNewPassword) {
        //     temp.newPassword = "";
        //     temp.confirmNewPassword = "";
        // } else {
        //     temp.newPassword = "Passwords dont match";
        //     temp.confirmNewPassword = "Passwords dont match"
        // }

        setErrors({
            ...temp,
        });

        if (fieldValues == values)
            return Object.values(temp).every((x) => x == "");
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const params_data = {
            id,
            values
        }
        if (validate()) {
            if (values.newPassword === values.confirmNewPassword) {
                dispatch(resetPassword(params_data))
                    .then((res) => {
                        setAlert(true)
                        dispatch(logout())
                    })
                    .catch((err) => {

                    })
            } else {
                setShowPasswordMatchError(true)
                setTimeout(() => {
                    setShowPasswordMatchError(false)
                }, 3000);
            }
        }
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(initialValues, true, validate);

    return (
        <section className={styles.parentContainer}>
            {
                alert ? (<div className={styles.overlayContainer}>
                    <h3>{message}</h3>
                    <br />
                    <Link to='/auth/login '>Go back to Login Page</Link>
                    {/* <h4>Go back to Login Page</h4> */}
                </div>) : (
                    <div className={styles.passwordContainer}>
                        <div className={styles.innerPasswordContainer}>
                            <div className={styles.headerParent}>
                                <h2 className={styles.header}>Reset Password</h2>
                            </div>

                            <div className={styles.formContainer}>
                                {
                                    showPasswordMatchError && (
                                        <Alert severity="info" sx={{ mt: 1 }}>
                                            Passwords dont match
                                        </Alert>
                                    )
                                }
                                <form autoComplete="off" onSubmit={handleSubmit}>
                                    <TextBox
                                        name="newPassword"
                                        label="New Password"
                                        onChange={handleInputChange}
                                        className={styles.textField}
                                        value={values.newPassword}
                                        error={errors.newPassword}
                                        sx={{ input: { color: '#FFF' }, label: { color: '#FFF' }, borderBottom: '2px solid #fff' }}
                                        variant="standard"
                                    />
                                    <TextBox
                                        name="confirmNewPassword"
                                        label="Confirm New Password"
                                        onChange={handleInputChange}
                                        className={styles.textField}
                                        value={values.confirmNewPassword}
                                        error={errors.confirmNewPassword}
                                        sx={{ input: { color: '#FFF' }, label: { color: '#FFF' }, borderBottom: '2px solid #fff' }}
                                        variant="standard"
                                    />
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        className={styles.submitButton}
                                        type="submit"
                                        text="Reset Password"
                                    />
                                </form>

                            </div>



                        </div>
                    </div>
                )
            }

        </section>
    )
}

export default ResetPassword