import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slice/auth";
import loanApplicationReducer from './slice/loanapplication'
import loanDisbursedReducer from './slice/loanDisbursed';
import finalDashboardReducer from './slice/finaldashboard'
import rejectedReducer from './slice/rejectedlist'
import forgotpasswordReducer from './slice/forgotpassword'
import customerListReducer from './slice/customerslists';
import customerSupportReducer from './slice/customerSupport';
import repaymentReducer from './slice/repayments';
import modalReducer from './slice/modal'
import notificationsReducer from './slice/header'
import commonslice from './slice/commonslice';

const reducer = {
  auth: authReducer,
  loanApplication: loanApplicationReducer,
  loanDisburse: loanDisbursedReducer,
  repayments: repaymentReducer,
  finaldashboard: finalDashboardReducer,
  rejected: rejectedReducer,
  forgotPassword: forgotpasswordReducer,
  customerList: customerListReducer,
  customerSupport: customerSupportReducer,
  modal: modalReducer,
  notifications: notificationsReducer,
  commonapi: commonslice,

}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;