import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DataService from '../services/DataService'

export const getRejectedList = createAsyncThunk(
    'rejected-list',
    async ({ startDate, endDate, searchQry, pageNum }) => {
        const url = `admin/reject-applications/?keyword=${searchQry}&page=${pageNum}&limit=10&fromDate=${startDate}&toDate=${endDate}`
        const res = await DataService.get(url)
        return res.data;
    }
)

const initialState = {
    isLoading: true,
    rejectedList: [],
    totalCount: ''
}

const rejectedListSlice = createSlice({
    name: 'rejected list',
    initialState,
    extraReducers: {
        [getRejectedList.pending]: (state, action) => {
            state.isLoading = true;
        },
        [getRejectedList.fulfilled]: (state, action) => {
            state.rejectedList = action.payload.applications
            state.totalCount = action.payload.dataCount
            state.isLoading = false;
        },
        [getRejectedList.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.error.message
        },
    }
})

export default rejectedListSlice.reducer