import React, { useContext } from 'react'
import { Grid } from '@mui/material';
import { CustomerData } from '../LoanApplication'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ModalImage from "react-modal-image";
import View from '../../../images/click_to_view_image.jpg'

function UploadedDocuments() {
    const customerData = useContext(CustomerData);
    return (
        <>
            <Grid container spacing={2} style={{ padding: '20px 70px' }} alignItems="center">

                <Grid item xs={4}>
                    <span className='stepper-label'>Aadhar Front:</span>
                </Grid>
                <Grid item xs={3}>
                    <div>
                        {
                            (customerData?.documentsDetails?.documents?.aadharFronturl) ?
                                <DoneIcon fontSize='medium' color='success' />
                                :
                                <CloseIcon fontSize='medium' color='error' />
                        }
                    </div>
                </Grid>
                <Grid item xs={5}>
                    {
                        (customerData?.documentsDetails?.documents?.aadharFronturl) ?
                            (
                                <ModalImage
                                    small={View}
                                    large={customerData?.documentsDetails?.documents?.aadharFronturl}
                                    hideDownload={true}
                                    hideZoom={true}
                                />
                            ) : ''
                    }
                </Grid>


                <Grid item xs={4}>
                    <span className='stepper-label'>Aadhar Back:</span>
                </Grid>
                <Grid item xs={3}>
                    <div>
                        {
                            (customerData?.documentsDetails?.documents?.aadharBackurl) ?
                                <DoneIcon fontSize='medium' color='success' />
                                :
                                <CloseIcon fontSize='medium' color='error' />
                        }
                    </div>
                </Grid>
                <Grid item xs={5}>
                    {
                        (customerData?.documentsDetails?.documents?.aadharBackurl) ?
                            (
                                <ModalImage
                                    small={View}
                                    large={customerData?.documentsDetails?.documents?.aadharBackurl}
                                    hideDownload={true}
                                    hideZoom={true}
                                />
                            ) : ''
                    }
                </Grid>


                <Grid item xs={4}>
                    <span className='stepper-label'>Pan:</span>
                </Grid>
                <Grid item xs={3}>
                    <div>
                        {
                            (customerData?.documentsDetails?.documents?.panurl) ?
                                <DoneIcon fontSize='medium' color='success' />
                                :
                                <CloseIcon fontSize='medium' color='error' />
                        }
                    </div>
                </Grid>
                <Grid item xs={5}>
                    {
                        (customerData?.documentsDetails?.documents?.panurl) ?
                            (
                                <ModalImage
                                    small={View}
                                    large={customerData?.documentsDetails?.documents?.panurl}
                                    hideDownload={true}
                                    hideZoom={true}
                                />
                            ) : ''
                    }
                </Grid>


                <Grid item xs={4}>
                    <span className='stepper-label'>Photo: </span>
                </Grid>
                <Grid item xs={3}>
                    <div>
                        {
                            (customerData?.documentsDetails?.documents?.photo) ?
                                <DoneIcon fontSize='medium' color='success' />
                                :
                                <CloseIcon fontSize='medium' color='error' />
                        }
                    </div>
                </Grid>
                <Grid item xs={5}>
                    {
                        (customerData?.documentsDetails?.documents?.photo) ?
                            (
                                <ModalImage
                                    small={View}
                                    large={customerData?.documentsDetails?.documents?.photo}
                                    hideDownload={true}
                                    hideZoom={true}
                                />
                            ) : ''
                    }
                </Grid>


                <Grid item xs={4}>
                    <span className='stepper-label'>PaySlip: </span>
                </Grid>
                <Grid item xs={3}>
                    <div>
                        {
                            (customerData?.documentsDetails?.documents?.paySlip) ?
                                <DoneIcon fontSize='medium' color='success' />
                                :
                                <CloseIcon fontSize='medium' color='error' />
                        }
                    </div>
                </Grid>
                <Grid item xs={5}>
                    {
                        (customerData?.documentsDetails?.documents?.paySlip) ?
                            (
                                <ModalImage
                                    small={View}
                                    large={customerData?.documentsDetails?.documents?.paySlip}
                                    hideDownload={true}
                                    hideZoom={true}
                                />
                            ) : ''
                    }
                </Grid>


                {/* <Grid item xs={4}>
                    <span className='stepper-label'>Bank Statement: </span>
                </Grid>
                <Grid item xs={3}>
                    <div>
                        {
                            (customerData?.documentsDetails?.documents?.bankStatement1) ?
                                <DoneIcon fontSize='medium' color='success' />
                                :
                                <CloseIcon fontSize='medium' color='error' />
                        }
                    </div>
                </Grid>
                <Grid item xs={5}>
                    {
                        (customerData?.documentsDetails?.documents?.bankStatement1) ? (
                            <ModalImage
                                small={View}
                                large={customerData?.documentsDetails?.documents?.bankStatement1}
                                hideDownload={true}
                                hideZoom={true}
                            />
                        ) : ''
                    }
                </Grid> */}

            </Grid>
        </>
    )
}

export default UploadedDocuments