import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(12, 4),
    },
    mainDiv: {
      // padding: "50px 0px 0px 200px",
      // ['@media (max-width: 1400px)']: {
      //   paddingLeft: "196px",
      // },
    },
    mainSection: {
      position: "relative",
      //top: "80px",
      //padding: "0 80px 30px 80px",
      //marginTop: "55px",
      overflowX:'scroll'
    },
    actionIcon: {
      color: "#5A5DF6 !important",
      // fontSize: "18px",
    },
    gridContainerParent: {
      paddingLeft: "30px",
      paddingTop: '20px',
      paddingBottom: '20px'
    },
    employeeItems: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#1A0045",
      letterSpacing: "0.08em",
    },
    lpselectDropdownBar: {
      position: "relative",
      width: "100%",
      borderRadius: "8px",
      height: "50px",
      // backgroundColor: "#ffffff",
      // boxShadow: '9px 9px 50px rgba(0, 0, 0, 0.12)',
      padding: '0 10px',
    },
    listHeading: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "800 !important",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#444445",
      letterSpacing: "0.08em",
    },
    listValues: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#444445",
      letterSpacing: "0.08em",
    },
    tabMainSection: {
      position: "relative",
      width:'100%',
      overflowX:'scroll'
      //top: "50px",
      //padding: "0 20px 30px 20px",
    },
    tabMainSectionTwo: {
      position: "relative",
      //top: "40px",
      //padding: "0 80px 30px 80px",
    },
    createChargeMainSection: {
      position: "relative",
      // top: "50px",
      // padding: "0 80px 30px 80px"
    },
    createChargePaper: {
      marginLeft: 'auto',
      marginRight: 'auto',
      boxShadow: '9px 9px 50px rgba(0, 0, 0, 0.12)',
      backgroundColor: '#fff',
      padding: '35px',
      borderRadius: '15px'
    },
    detailsCard: {
      position: "relative",
      left: "0px",
      padding: theme.spacing(7),
      overflowY: "hidden",
      overflowX: "hidden",
      margin: '50px',
      marginLeft: '100px',
      // height: '100%',
      background: "#FEFEFE",
      marginTop: "40px",
      borderRadius: "16px",
      boxShadow: "9px 9px 50px rgba(0, 0, 0, 0.12)",
    },
    pageContent: {
      height: "100%",
      //minHeight: "100vh",
      position:'relative',
      display:'flex',
      flexDirection:'column',
      justifyContent:'space-between',
      gap:'50px',
      background:'transparent',
      boxShadow:'none'
    },
    pageContentOne: {
      position: "relative",
      left: "0px",
      padding: theme.spacing(7),
      overflowY: "hidden",
      overflowX: "hidden",
      // height: '100%',
      minHeight: "calc(100vh - 70px)",
    },
    tableBoxShadow: {
      boxShadow: "9px 9px 50px rgba(0, 0, 0, 0.12)"
    },
    parentContainer: {
      width: "75vw",
      paddingLeft: "100px",
      paddingTop: "50px",
      paddingBottom: "10px",
      backgroundColor: "#ffffff",
      borderRadius: "15px",
    },
    headerOutlay: {
      // position: "relative",
      // marginLeft: "122px",
      // marginTop: "50px",
    },
    mainHeading: {
      fontSize: "30px",
      lineHeight: "45px",
      color: "#963460",
      fontWeight: "600",
      letterSpacing: "0.08em",
      ['@media (max-width: 1200px)']: {
        fontSize: "20px",
      },

    },
    subHead: {
      //position: "absolute",
      //width: "555px",
      //height: "15px",
      //top: "50px",
      fontWeight: "300",
      fontSize: "10px",
      lineHeight: "15px",
      color: "#242424",
      letterSpacing: "0.08em",
      margin:0
    },
    backButton: {
      //position: "absolute",
      //left: "-60px",
      color: "#963460",
      //lineHeight: "45px",
      fontWeight: "600",
      //fontSize: "30px",
    },
    datePicker: {
      backgroundColor: '#fff',
      boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.1)',
      borderRadius: "8px",
      height: "50px",
      position: 'relative',
      //right: '20px',
      width: '20rem'
    },
    searchBar: {
      position: "relative",
      width: "80vw",
      left: "280px",
      display: "flex",
    },
    lpsearchBar: {
      // position: "relative",
      // left: "50px",
      // height: "50px",
      // width: "80vw",
      // ['@media (max-width: 1400px)']: {
      //   width: "51vw",
      // }
    },
    lpsearchTextBar: {
      position: "relative",
      width: "320px",
      borderRadius: "8px",
      padding: "10px, 10px,10px,0px",
      backgroundColor: "#ffffff",
      boxShadow: '9px 9px 50px rgba(0, 0, 0, 0.12)',
      ['@media (max-width: 900px)']: {
        width: "250px"
      }
    },
    download: {
      width: "250px",
      height: "50px",
      backgroundColor: "#5A5DF6",
      color: "#ffffff",
      borderRadius: "5px",
      fontSize: "15px",
      "&:hover": {
        backgroundColor: "#5A5DF6",
      },
      ['@media (max-width: 1064px)']: {
        //width: "150px",
      },
      ['@media (max-width: 960px)']: {
        //width: "100px",
        //fontSize: "10px",
      },
      ['@media (max-width: 913px)']: {
        //width: "100px",
        //fontSize: "8px",
      }
    },
    exceldownload: {
      width: "250px",
      height: "50px",
      backgroundColor: "#5A5DF6",
      color: "#ffffff",
      borderRadius: "5px",
      fontSize: "15px",
      marginRight: '18px',
      "&:hover": {
        backgroundColor: "#5A5DF6",
      },
      ['@media (max-width: 1064px)']: {
        width: "150px",
      },
      ['@media (max-width: 960px)']: {
        width: "100px",
        fontSize: "10px",
      },
      ['@media (max-width: 913px)']: {
        width: "100px",
        fontSize: "8px",
      }
    },
    searchAddParent: {
      // backgroundColor: 'green',
      //marginLeft: "20px",
      //padding: '0 0 0 50px',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      //top: '50px',
      justifyContent:'space-between',
      //width: '80vw',
      flexWrap:'wrap',
      gap:'20px'
    },
    searchTextBar: {
      position: "relative",
      //left: "30px",
      width: "360px",
      borderRadius: "8px",
      padding: "10px, 10px,10px,0px",
      backgroundColor: "#ffffff",
      boxShadow: '9px 9px 50px rgba(0, 0, 0, 0.12)',
      ['@media (max-width: 1200px)']: {
        width: "250px",
      }
    },
    toolBar: {
      position: "relative",
      //top: "50px",
      //height: "10px",
      left: "0px",
    },
    tabToolbar: {
      padding:0,
      position: "relative",
      //top: "10px",
      //height: "10px",
      //left: "-30px"
    },
    newButton: {
      position: "relative",
      top: "10px",
      left: "300px",
      width: "200px",
      height: "50px",
      backgroundColor: "#5A5DF6",
      borderRadius: "5px",
      fontSize: "15px",
      color: "white",
      textTransform: "none",
      fontWeight: 850,
      lineHeight: "50px",
      padding: theme.spacing(2, 0),
    },
    addNewLoanButton: {
      position: "relative",
      width: "250px",
      height: "45px",
      backgroundColor: "#5A5DF6",
      borderRadius: "5px",
      fontSize: "15px",
      color: "white",
      textTransform: "none",
      fontWeight: 850,
      lineHeight: "50px",
      ['@media (max-width:900px)']: {
        //width: "100px"
      }
    },
    partnercard: {
      position: "absolute",
      width: "1200px",
      // height: '661px',
      left: "300px",
      top: "50px",
      boxShadow: "9px 9px 50px rgba(0, 0, 0, 0.12)",
      borderRadius: "16px",
      backgroundColor: "#E5E5E5",
    },
    partnerButton: {
      backgroundColor: "#FFFFFF",
      color: "black",
      borderRadius: "8px",
      padding: "10px 10px",
      width: "250px",
      display: "inline-block",
      fontStyle: "normal",
      fontWeight: "500",
      "&:hover": {
        backgroundColor: "white",
      },
      textAlign: "center",
    },
    listcontainer: {
      marginTop: "10px",
    },
    tableCell: {
      textAlign: "center",
      color: "#444445!important",
      fontWeight: "400!important",
      lineHeight: "18px",
      fontSize: "14px!important",
    },
    searchIconColour: {
      color: "#963460",
      height: "25px",
    },
    headContainer: {
      display: "flex !important",
      gap:'20px',
      width: "45%",
      position: "absolute",
      right: "0px",
      top: "0px",
      justifyContent: "space-between !important",
      ['@media (max-width: 1391px)']: {
        // width: "30% !important",
        // right: "80px",
      },
      ['@media (max-width: 1080px)']: {
        width: "45% !important",
        right: "0px",
        top: "0px",
      }
    },
    innerHeadContainer: {
      backgroundColor: "#6b47a7",
      //width: "48% !important",
      //height: "80px !important",
      borderRadius: "15px",
      display: "flex !important",
      alignItems: "center !important",
      justifyContent: "center !important",
      flexDirection: "column !important",
      padding:'10px 40px',
      gap:'10px'
    },
    containerHeader: {
      color: "#ffffff",
      fontSize: "18px",
      margin:0,
      //marginTop: "10px",
      ['@media (max-width: 1391px)']: {
        fontSize: "15px"
      },
      ['@media (max-width: 820px)']: {
        fontSize: "12px"
      }
    },
    containerBody: {
      color: "#ffffff",
      fontSize: "16px",
      fontWeight: "bold",
      margin:0
    },
    lstcontainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '-50px',
    },
    lsttypography: {
      color: '#963460',
      fontWeight: 'bold',
      marginTop: '50px',
    },
    lsttableLeft: {
      maxWidth: '100%',
      width: '100%'
    },
    lsttableRight: {
      maxWidth: '100%',
      width: '100%'
    },
    lsttable: {
      border: '2px solid #1A0045'
    },
    loanStatementCards: {
      boxShadow: "9px 9px 50px rgba(0, 0, 0, 0.12)",
      position: 'relative',
      display: 'flex',
      justifyContent: 'stretch'
    },
    loanStatementSummaryTableCaption: {
      position: 'relative',
      top: '95px',
      color: '#963460',
      fontWeight: 'bold !important'
    },
    loanStatementTransactionTableCaption: {
      position: 'relative',
      top: '95px',
      color: '#963460',
      fontWeight: 'bold !important',
    },
    loanDetailsTableOneCaption: {
      color: '#963460',
      fontWeight: 'bold !important',
      position: 'relative',
      top: '85px'
    },
    cardSection: {
      marginTop: '90px'
    },
    downloadPdfParent: {
      padding: '0 80px',
      position: 'relative',
      bottom: '30px'
    },
    downloadPdfButton: {
      backgroundColor: '#4E41D9',
      textTransform: 'none',
      position: 'absolute',
      right: '90px'
    },
    creditReportContainer: {
      width: '100%',
      paddingLeft: '50px'
    },
    paymentHistoryHeadbar: {
      width: '100%',
      height: '100px',
      backgroundColor: '#963460',
      display: 'flex',
      padding: '0 20px',
    },
    paymentHistoryHeadbarCellOne: {
      width: '33.33%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    cellOneHeader: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '27px',
      color: '#ffffff'
    },
    cellOneSubHeader: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '30px',
      color: '#ffffff'
    },
    paymentHistoryHeadbarCellTwo: {
      width: '33.33%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paymentHistoryHeadbarCellThree: {
      width: '33.33%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'space-around',
    },
    cellThreeButton: {
      color: '#963460',
      fontSize: '10px',
      fontFamily: 'Poppins',
      backgroundColor: '#ffffff',
      width: '76px',
      height: '21px',
      border: '1px solid #ffffff',
      borderRadius: '8px',
      cursor: 'pointer'
    },
    cellThreeHeader: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#FFFFFF',
      display: 'flex',
      alignItems: 'center',
    },
    paymentHistoryBodyCellsContainer: {
      height: '400px',
      width: '100%',
      overflowY: "scroll"
      // backgroundColor: 'green'
    },
    paymentHistoryBodyCells: {
      width: '95%',
      height: '75px',
      background: '#FFFFFF',
      borderRadius: '16px',
      margin: '10px 0',
      border: '1px solid #963460',
      boxShadow: '-5px 0px #963460',
      display: 'flex',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    bodyCellOne: {
      width: '20%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    bodyCellTwo: {
      width: '20%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    bodyCellThree: {
      width: '25%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    bodyCellContent: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#000000',
    },
    bodyCellFour: {
      width: '15%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paymentHistoryBackButtonParent: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    paymentHistoryBackButton: {
      border: '1px solid #5A5DF6',
      color: '#5A5DF6',
      boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      backgroundColor: '#ffffff'
    },
    cbReportModalHeader: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '36px !important',
      letterSpacing: '0.08em',
      color: '#963460',
      textAlign: 'center'
    },
    boxTitle: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '17px',
      lineHeight: '24px',
      color: '#242424',
    },
    ebrBodyCells: {
      width: '100%',
      backgroundColor: '#FFFFFF',
      height: '110px',
      borderRadius: '4px',
      boxShadow: '0px 2px 17px 4px rgba(0, 0, 0, 0.16)',
      display: 'flex'
    },
    ebrBodyTabHeader: {
      padding: '25px 0 15px 0',
      color: '#963460',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '21px',
      textDecoration: 'underline'
      // borderBottom: '2px solid black'
    },
    ebrBackButton: {
      border: '1px solid #5A5DF6',
      color: '#5A5DF6',
      boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      backgroundColor: '#ffffff',
      margin: '20px 0'
    },
    ebrBodyCellLeft: {
      width: '33.33%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    ebrBodyCellRight: {
      width: '66.66%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      padding: '25px 0 15px 0'
    },
    redCircle: {
      width: '50px',
      height: '50px',
      backgroundColor: '#EF0B0B',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    violetCircle: {
      width: '50px',
      height: '50px',
      backgroundColor: '#7B4FFF',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    ebrBodyCellContent: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '18px'
    }

  })
);
