import React from 'react'
import { Modal, Box, Typography } from '@mui/material';
import moment from 'moment';
import useStyles from './NotificationsModalStyles';

const style = {
    position: 'absolute',
    top: '70px',
    right: '5px',
    width: 350,
    height: '450px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px'
};


const NotificationsModal = ({ open, handleClose, notificationList }) => {

    const classes = useStyles()

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Box className={classes.headerParent}>
                        <h3 className={classes.header}>Notifications</h3>
                    </Box>
                    <Box className={classes.notifBodyParent}>
                        {
                            notificationList && notificationList.map(item =>
                                <>
                                    <Box className={classes.singleNotifParent}>
                                        <Typography variant="body2" color="#01295F" gutterBottom>
                                            {item.description}
                                        </Typography>
                                        <Typography variant="body2" color="gray" gutterBottom>
                                            {moment(item.createdAt).startOf('day').fromNow()}
                                        </Typography>
                                        {
                                            item.status === 0 ? <div className={classes.newNotifCircle} /> : ''
                                        }
                                    </Box>
                                    <hr />
                                </>
                            )
                        }
                    </Box>
                    <Box className={classes.notifFooter} />
                </Box>
            </Modal>
        </>
    )
}

export default NotificationsModal