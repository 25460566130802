import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Grid,
} from "@material-ui/core";
import Typography from "../../controls/Typography";
import Personal from "./Personal";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { CustomerData } from "../LoanApplication";
import Official from "./Official";
import Bank from "./Bank";
import UploadedDocuments from "./UploadedDocuments";
import IdentityVerification from "./IdentityVerification";
import CreditScore from "./CreditScore";
import IncomeVerification from "./IncomeVerification";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: '#01295F !important'
  },
}));

const ReviewLoanAppli = (props) => {
  const classes = useStyles();
  const customerData = useContext(CustomerData);
  const { applicDetails } = useSelector((state) => state.loanApplication);

  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const [resourceId, setResourceId] = useState();
  const steps = getSteps();

  useEffect(() => {
    console.log('applicDetails => ', applicDetails);
    setResourceId(
      applicDetails &&
      applicDetails.loanapproval &&
      applicDetails.loanapproval.resourceId
    );
  }, [dispatch, applicDetails]);

  function getSteps() {
    return ["Personal", "Employment", "Bank", "Documents Uploaded", "Identity Verification", "Credit Score", "Income Verification"];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Personal />;
      case 1:
        return <Official />;
      case 2:
        return <Bank />;
      case 3:
        return <UploadedDocuments />;
      case 4:
        return <IdentityVerification />;
      case 5:
        return <CreditScore />;
      case 6:
        return <IncomeVerification />;
      default:
        return "unknown step";
    }
  };

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  // handleFinish approves the loan apllication and goes to the loan creation
  // const handleFinish = () => {
  //   if (customerData.id) {
  //     const data = {
  //       loanapproval_id:
  //         customerData &&
  //         customerData.loanapproval &&
  //         customerData.loanapproval._id,
  //     };
  //     // dispatch(nbfcApprove(data))
  //     props.data.handleClose();
  //   }
  // };
  // const handleReject = () => {
  //   const data = {
  //     loanapproval_id:
  //       customerData &&
  //       customerData.loanapproval &&
  //       customerData.loanapproval._id,
  //   };
  //   // dispatch(nbfcReject(data));
  //   props.data.handleClose();
  // };

  const disburseLoan = () => {
    alert("Disburse application");
  };

  const rejectLoan = () => {
    alert("Reject application");
  }

  return (
    <>
      <IconButton style={{ marginLeft: "920px" }} onClick={props.data.handleClose}>
        <CloseIcon />
      </IconButton>

      <Typography
        text={"Review Loan Application"}
        className="stepperHeading"
      ></Typography>
      {/* <Typography text={"Employee ID"} className="stepSubHeading"></Typography> */}
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
          Thank You
        </Typography>
      ) : (
        <>
          <form>{getStepContent(activeStep)}</form>
          <Grid container spacing={2} style={{ marginLeft: "94px", paddingTop: "auto" }}>
            <Grid item xs={8}>
              {
                activeStep !== 0 &&
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  back
                </Button>
              }

              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={
                  activeStep === steps.length - 1
                    ?
                    disburseLoan
                    : handleNext
                }
              >
                {activeStep === steps.length - 1
                  ?
                  "Disburse"
                  : "Next"}
              </Button>

              { activeStep === steps.length - 1 ? 
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={rejectLoan}
                >
                  Reject
                </Button> : ''
              }

            </Grid>
          </Grid>
        </>
      )}
      {/* {
        openRejModal ? <NbfcConfDialog open={openRejModal} handleClose={handleNbfcRejClose} handleAction={handleReject} msg={nbfcRejMsg} /> : null
      }
      {
        openAppModal ? <NbfcConfDialog open={openAppModal} handleClose={handleNbfcAppClose} handleAction={handleNbfcApprove} msg={nbfcAppMsg} /> : null
      } */}
    </>
  );
};

export default ReviewLoanAppli;
