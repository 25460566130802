import { Box, IconButton, Typography } from '@mui/material'
import React, { useContext } from 'react'
import ReactSpeedometer from "react-d3-speedometer";
import { CustomerData } from '../LoanApplication'
import moment from 'moment'
import { MdOutlineManageAccounts, MdOutlinePayments, MdModelTraining } from 'react-icons/md'
import { BsPeople } from 'react-icons/bs'

const CreditScore = () => {

    const textColor = "#AAA";
    const customerData = useContext(CustomerData);
    console.log("Credit Score Customer Data", customerData)
    const score_data = customerData && customerData.application && customerData.application.creditScore
    const date = customerData.application.createdAt;

    return (
        <>
            <Box className="credit-score-parent">
                <Box className='credit-score-indicator-parent'>
                    <ReactSpeedometer
                        needleHeightRatio={0.7}
                        maxSegmentLabels={4}
                        segments={5555}
                        minValue={400}
                        value={score_data}
                        textColor={textColor}
                        // width={400}
                        ringWidth={47}
                        width={230}
                    />
                    <div className="outercircle">
                        <p className="value">{score_data}</p>
                        <p className="text">{(score_data < 400) ? "Not Eligible" :
                            (score_data >= 400 && score_data < 600) ? "Poor" :
                                (score_data >= 600 && score_data < 800) ? "Good" :
                                    (score_data >= 800 && score_data <= 1000) ? "Fair" :
                                        (score_data > 1000) ? "Excellent" :
                                            ""
                        }</p>
                    </div>
                    <p className='credit-score-title'>Your credit score for {moment(date).format("DD MMMM YYYY")}</p>
                </Box>

                <Box className='credit-score-details-parent'>

                    <Box className='credit-score-details-elements'>
                        <Typography variant="subtitle1" color="#01295f" sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton disabled><MdOutlineManageAccounts color="#01295f" /></IconButton> Active Accounts
                        </Typography>
                        <Typography variant="subtitle1" color="#01295f">
                            {customerData && customerData.cibilDatas && customerData.cibilDatas.active_accounts}
                        </Typography>
                    </Box>
                    <hr style={{backgroundColor:'#01295f'}} />

                    <Box className='credit-score-details-elements' sx={{ mt: 1 }}>
                        <Typography variant="subtitle1" color="#01295f" sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton disabled><BsPeople color="#01295f" /></IconButton> Enquiry Summary
                        </Typography>
                        <Typography variant="subtitle1" color="#01295f">
                            {customerData && customerData.cibilDatas && customerData.cibilDatas.enquirySummary_past30days}
                        </Typography>
                    </Box>
                    <hr style={{backgroundColor:'#01295f'}} />

                    <Box className='credit-score-details-elements' sx={{ mt: 1 }}>
                        <Typography variant="subtitle1" color="#01295f" sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton disabled><MdOutlinePayments color="#01295f" /></IconButton> Total Monthly Payment
                        </Typography>
                        <Typography variant="subtitle1" color="#01295f">
                            {customerData && customerData.cibilDatas && customerData.cibilDatas.totalMonthlyPaymentAmount}
                        </Typography>
                    </Box>
                    <hr style={{backgroundColor:'#01295f'}} />

                    <Box className='credit-score-details-elements' sx={{ mt: 1 }}>
                        <Typography variant="subtitle1" color="#01295f" sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton disabled><MdModelTraining color="#01295f" /></IconButton> Total Past Due
                        </Typography>
                        <Typography variant="subtitle1" color="#01295f">
                            {customerData && customerData.cibilDatas && customerData.cibilDatas.total_past_due}
                        </Typography>
                    </Box>
                    <hr style={{backgroundColor:'#01295f'}} />

                </Box>
            </Box>
        </>

    )
}

export default CreditScore