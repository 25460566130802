import React from 'react'
import { Dialog, DialogContent, DialogContentText, DialogActions, Button, Box, Typography, IconButton } from '@mui/material'
import classes from './CustomerStyle'
import { AiOutlineClose } from 'react-icons/ai';

const DialogCustomerSupport = ({ open, handleClose, handleAccept, handleReject, bodyText, statusText }) => {

    const styles = classes()

    return (
        <>
            <Dialog
                open={open}
            >
                <Box className={styles.headerParent}>
                    <Typography variant="h6" fontWeight={'bold'} color="#01295F">
                        {statusText} Token?
                    </Typography>
                    <IconButton onClick={handleClose} className={styles.closeIconButton}>
                        <AiOutlineClose />
                    </IconButton>
                </Box>

                <DialogContent>
                    <DialogContentText sx={{ color: '#01295F' }}>
                        {bodyText}
                    </DialogContentText>
                </DialogContent>

                <DialogActions className={styles.dialogButtonsParent}>
                    <Button
                        className={styles.acceptChatButton}
                        variant="contained"
                        onClick={statusText === 'Accept' ? () => handleAccept() : () => handleReject()}
                        autoFocus
                    >
                        Yes
                    </Button>
                    <Button
                        className={styles.rejectChatButton}
                        variant="outlined"
                        onClick={handleClose}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DialogCustomerSupport