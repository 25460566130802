import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    headerParent: {
        backgroundColor: '#72ebee', 
        height: '60px', 
        borderRadius: '20px 20px 0 0', 
        position: 'relative'
    },
    closeButton: {
        position: 'absolute', 
        right: '7px', 
        top: '5px'
    },
    
})
)

export default useStyles