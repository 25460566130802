import { useState } from "react";
import { makeStyles } from "@material-ui/core/";

export const useSnackbar = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const useStyles = makeStyles((theme) => ({
    snackbarStyleViaContentProps: {
      backgroundColor: '#963460'
    },
  }));
  const handleOpen = msg => {
    setOpen(true);
    setMessage(msg);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
    setMessage(undefined);
  };
  const classes = useStyles();
  return {
    open,
    message,
    openSnackbar: handleOpen,
    onClose: handleClose,
    autoHideDuration: 2000,
    anchorOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    ContentProps: {
      "aria-describedby": "message-id",
      className: classes.snackbarStyleViaContentProps
    }
  };
};
