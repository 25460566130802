import Axios from "axios";
import dayjs from 'dayjs';
import jwt_decode from "jwt-decode";

//................................................................
const baseURL = process.env.REACT_APP_API_URL;

export const http = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  }
});

http.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem("accessToken");
  let refreshToken = localStorage.getItem("refreshToken");
  config.headers["Authorization"] = `Bearer ${accessToken}`;

  const user = jwt_decode(accessToken)
  const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1

  if (!isExpired) return config;

  const response = await Axios.post(`${baseURL}admin/refresh-token`, {
    token: refreshToken
  })
  localStorage.setItem('accessToken', response.data.accessToken)
  config.headers["Authorization"] = `Bearer ${response.data.accessToken}`;

  return config;
});

http.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);
//................................................................

export const instance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Accept: "application/json" },
});
instance.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem("accessToken");
  config.headers["Authorization"] = `Bearer ${accessToken}`;
  return config;
});
//................................................................

// http.interceptors.request.use(async (config) => {
//   let accessToken = localStorage.getItem("accessToken");

//   config.headers["authorization"] = accessToken;
//   return config;
// });


//................................................................
instance.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem("accessToken");
  config.headers["authorization"] = `Bearer ${accessToken}`;
  return config;
});

instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);
//.................................................................