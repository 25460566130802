import React, { useState, useEffect } from "react";
import { TableBody, TableRow, TableCell, Button, TextField, IconButton } from "@material-ui/core";
import useTable from "../Sharedfeatures/UseTable";
import { useDispatch, useSelector } from "react-redux";
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import HeadCells from "../common/HeadCells";
import { getLoanDisbursedList, resetDisburseDetails, viewLoanDisbursedDetails } from "../../slice/loanDisbursed";
import ProgressSpinner from "../common/ProgressSpinner";
import { PAGE_SIZE } from "../../utils/variable";
import { TXT_LOAN_DISBURSED, TXT_HOME } from "../../utils/header";
import { SearchOutlined } from "@material-ui/icons";
import PageHeader from "../Sharedfeatures/PageHeader";
import { openModal, closeModal } from "../../slice/modal";
import moment from 'moment';
import DisbursedDetailsModal from "./DisbursedDetailsModal";
import { DatePicker } from 'antd';
import EastIcon from '@mui/icons-material/East';

const LoanDisbursed = () => {

  const { RangePicker } = DatePicker;
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [showPageNtn, setShowPageNtn] = useState(false);
  const [search, setSearch] = useState("");

  const sharedClasses = useSharedClasses();
  const dispatch = useDispatch();
  const { disburseList, totalCount, isLoading, disburseDetails } = useSelector((store) => store.loanDisburse);
  const { isModalOpen } = useSelector((store) => store.modal)
  const { TblContainer, TblHead, TblPagination, pageNo, slNo } = useTable(disburseList, HeadCells.loanDisbursedHead, totalCount);

  useEffect(() => {
    const data = {
      pageNum: pageNo,
      searchQry: search,
      startDate,
      endDate
    };
    dispatch(getLoanDisbursedList(data));
    setShowPageNtn(true);
  }, [dispatch, pageNo, search, startDate, endDate]);

  let serialNo = PAGE_SIZE * slNo;

  const dateFormat = "MM-DD-YYYY"

  const handleOpenModal = (applicationNo) => {
    dispatch(viewLoanDisbursedDetails(applicationNo))
    dispatch(openModal())
  }

  const handleCloseModal = () => {
    dispatch(closeModal())
    dispatch(resetDisburseDetails())
  }

  return (
    <>
      <div className={sharedClasses.mainDiv}>
        <div className={sharedClasses.pageContent}>
          <PageHeader
            header={TXT_LOAN_DISBURSED}
            subheader={`${TXT_HOME}/${TXT_LOAN_DISBURSED}`}
          />
          <article className={sharedClasses.searchAddParent}>
            <div className={sharedClasses.lpsearchBar}>
              <TextField
                size="small"
                className={sharedClasses.lpsearchTextBar}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                id="standard-bare"
                variant="outlined"
                autoComplete="off"
                placeholder="Search By Loan A/C No"
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchOutlined
                        className={sharedClasses.searchIconColour}
                      />
                    </IconButton>
                  ),
                }}
              />
            </div>
            <RangePicker
              placeholder={["From MMDDYYYY", "To MMDDYYYY"]}
              format={dateFormat}
              className={sharedClasses.datePicker}
              onChange={(date, dateString) => {
                setStartDate(dateString[0])
                setEndDate(dateString[1])
              }}
              separator={<EastIcon style={{ color: '#bfbfbf' }} />}
            />
          </article>

          <section className={sharedClasses.mainSection}>
            <TblContainer>
              <TblHead />
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8} align='center'>
                      <ProgressSpinner />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {
                    (disburseList.length !== 0) ? (
                      disburseList &&
                      disburseList.map((item, key) => {
                        serialNo++;
                        key++;
                        return (
                          <TableRow key={key.toString()}>
                            <TableCell>
                              {serialNo}
                            </TableCell>
                            <TableCell align='center' className={sharedClasses.tableCell}>
                              {item.loanNo}
                            </TableCell>
                            <TableCell align='center' className={sharedClasses.tableCell}>
                              {item.userName}
                            </TableCell>
                            <TableCell align='center' className={sharedClasses.tableCell}>
                              {item.tenure}
                            </TableCell>
                            <TableCell align='center' className={sharedClasses.tableCell}>
                              {item.amount}
                            </TableCell>
                            <TableCell align='center' className={sharedClasses.tableCell}>
                              {item.interest}
                            </TableCell>
                            <TableCell align='center' className={sharedClasses.tableCell}>
                              {moment(item.startDate).format('DD MMMM YYYY')}
                            </TableCell>
                            <TableCell align='center' className={sharedClasses.tableCell}>
                              <Button variant="outlined" color="primary" onClick={() => handleOpenModal(item.applicationNo)}>
                                Show
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} align='center' className={sharedClasses.tableCell}>No Data to Show</TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              )}
            </TblContainer>
            {showPageNtn ? <TblPagination /> : ""}
          </section>
        </div>
      </div>
      {
        isModalOpen ? <DisbursedDetailsModal open={isModalOpen} handleClose={handleCloseModal} data={disburseDetails} /> : ''
      }
    </>
  );
};

export default LoanDisbursed;