import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    parentContainer: {
        width: '100vw',
        height: '100vh',
        backgroundColor: '#72ebee',
        background: 'radial-gradient(circle at 50 % 50 %, rgba(255, 255, 255, 1) 15 %, rgba(114, 235, 238, 1) 69 %)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    passwordContainer: {
        width: '400px',
        height: '450px',
        backgroundColor: '#01295F',
        borderRadius: '10px',
        padding: '20px 30px',
        // background: '-moz - radial - gradient(circle at 50 % 50 %, rgba(255, 255, 255, 1) 15 %, rgba(114, 235, 238, 1) 69 %)',
        // background: '-webkit - radial - gradient(circle at 50 % 50 %, rgba(255, 255, 255, 1) 15 %, rgba(114, 235, 238, 1) 69 %)',
        // background:' -o - radial - gradient(circle at 50 % 50 %, rgba(255, 255, 255, 1) 15 %, rgba(114, 235, 238, 1) 69 %)',
        // background:' -ms - radial - gradient(circle at 50 % 50 %, rgba(255, 255, 255, 1) 15 %, rgba(114, 235, 238, 1) 69 %)',
        // background: 'radial-gradient(circle at 50 % 50 %, rgba(255, 255, 255, 1) 15 %, rgba(114, 235, 238, 1) 69 %)',
    },
    overlayContainer: {
        width: '400px',
        height: '450px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        padding: '20px 30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    innerPasswordContainer: {
        width: '100%',
        height: '100%'
    },
    headerParent: {
        width: '100%',
        // height: '25%', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    header: {
        fontFamily: 'Red Rose',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '40px',
        // lineHeight: '50px', 
        color: '#fff'
    },
    formContainer: {
        width: '100%',
        height: '75%'
    },
    formInfoParent: {
        width: '100%',
        backgroundColor: 'rgb(78 65 217 / 48%)',
        color: '#fff',
        padding: '10px 10px',
        border: '2px solid #01295F',
        borderRadius: '5px'
    },
    textField: {
        width: '100%',
        margin: '20px 0 !important',
        color: '#fff',
        
        '& label.Mui-focused': {
            color: '#FFF',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#FFF',
            },
            '&:hover fieldset': {
                borderColor: '#FFF',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#FFF',
            },
            '&::placeholder': {
                textOverflow: 'ellipsis !important',
                color: '#FFF'
            },
        }
    },
    submitButton: {
        backgroundColor: '#24A0ED !important',
        height: '48px',
        borderRadius: '8px',
        margin: '20px 0 !important'
    }
})

export default useStyles