import React, { useEffect, useState } from "react";
import { Paper, TableBody, TableRow, TableCell, IconButton, Toolbar, TextField } from "@material-ui/core";
import useSharedClasses from "../Sharedfeatures/SharedClasses";
import { TXT_REJ_APPLN, TXT_HOME } from "../../utils/header";
import { useDispatch, useSelector } from "react-redux";
import useTable from "../Sharedfeatures/UseTable";
import HeadCells from "../common/HeadCells";
import PageHeader from "../Sharedfeatures/PageHeader";
import { getRejectedList } from '../../slice/rejectedlist'
import { SearchOutlined } from "@material-ui/icons";
import { PAGE_SIZE } from "../../utils/variable";
import ProgressSpinner from "../common/ProgressSpinner";
import { DatePicker } from 'antd';
import EastIcon from '@mui/icons-material/East';

const RejectedList = () => {

  const { RangePicker } = DatePicker;

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  
  const sharedClasses = useSharedClasses();
  const dispatch = useDispatch()
  const { isLoading, rejectedList, totalCount } = useSelector((store) => store.rejected)

  const { TblContainer, TblHead, TblPagination, pageNo, slNo } = useTable(rejectedList, HeadCells.rejectedApplnHead, totalCount);
  const [search, setSearch] = useState("")
  const [showPageNtn, setShowPageNtn] = useState(false);
  const moment = require("moment");

  useEffect(() => {
    const data = {
      pageNum: pageNo,
      searchQry: search,
      startDate,
      endDate
    };
    dispatch(getRejectedList(data))
    setShowPageNtn(true);
  }, [dispatch, pageNo, search, startDate, endDate])

  let serialNo = PAGE_SIZE * slNo;

  const dateFormat = "MM-DD-YYYY"

  return (
    <>
      <div className={sharedClasses.mainDiv}>
        <div className={sharedClasses.pageContent}>
          <PageHeader
            header={TXT_REJ_APPLN}
            subheader={`${TXT_HOME}/${TXT_REJ_APPLN}`}
          />

          <article className={sharedClasses.searchAddParent}>
            <div className={sharedClasses.lpsearchBar}>
              <TextField
                size="small"
                className={sharedClasses.lpsearchTextBar}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                id="standard-bare"
                variant="outlined"
                autoComplete="off"
                placeholder="Search By Application No"
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchOutlined
                        className={sharedClasses.searchIconColour}
                      />
                    </IconButton>
                  ),
                }}
              />
            </div>
            <RangePicker
              placeholder={["From MMDDYYYY", "To MMDDYYYY"]}
              format={dateFormat}
              className={sharedClasses.datePicker}
              onChange={(date, dateString) => {
                setStartDate(dateString[0])
                setEndDate(dateString[1])
              }}
              separator={<EastIcon style={{ color: '#bfbfbf' }} />}
            />
          </article>

          <section className={sharedClasses.mainSection}>
            <TblContainer>
              <TblHead />
              {
                isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={10} align='center'>
                        <ProgressSpinner />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {
                      (rejectedList.length !== 0) ? (
                        rejectedList &&
                        rejectedList.map((item, key) => {
                          serialNo++;
                          key++;
                          return (
                            <TableRow key={key.toString()}>
                              <TableCell>{serialNo}</TableCell>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {item.applicationNumber}
                              </TableCell>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {item.user.firstName}  {item.user.middleName}  {item.user.lastName}
                              </TableCell>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {moment(item.createdAt).format('DD MMMM YYYY')}
                              </TableCell>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {moment(item.updatedAt).format('DD MMMM YYYY')}
                              </TableCell>
                              <TableCell align='center' className={sharedClasses.tableCell}>
                                {item.reason}
                              </TableCell>

                              {/* {Number(item.loan_disbursed).toFixed(2)} */}
                              {/* {item.last_repayment === "No repayments" ? item.last_repayment : moment(item.last_repayment).format("DD-MM-YYYY")} */}

                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={10} align='center' className={sharedClasses.tableCell}>No Data to Show</TableCell>
                        </TableRow>
                      )
                    }
                  </TableBody>
                )
              }

            </TblContainer>
            {showPageNtn ? <TblPagination /> : ""}
          </section>
        </div>
      </div>
    </>
  );
};

export default RejectedList;
