import { makeStyles } from "@material-ui/core";

const classes = makeStyles(themes => ({
    closeBtnParent: {
        position: 'absolute',
        right: '10px',
        top: '10px'
    },
    chatAreaParent: {
        // border: '1px solid green',
        height: '71%',
        overflowY: 'auto',
    },
    chatAreaParentInner: {
        height: '100%',
        overflowY: 'auto',
        position: 'relative'
    },
    sentMessage: {
        backgroundColor: '#01295F', 
        maxWidth: '70%', 
        padding: '10px', 
        margin: '5px 0', 
        borderRadius: '5px', 
        color: '#fff', 
        position: 'relative', 
        right: '-80px'
    },
    receivedMessage: {
        backgroundColor: '#01295F', 
        maxWidth: '70%', 
        padding: '10px', 
        margin: '5px 0', 
        borderRadius: '5px', 
        color: '#fff', 
        position: 'relative'
    },
    finishButtonParent: {
        display: 'flex', 
        justifyContent: 'center'
    },
    finishButton: {
        backgroundColor: '#01295F !important',
        color: '#fff !important',
        padding: '5px 15px !important'
    },
    headerParent: {
        backgroundColor: '#72ebee', 
        height: '50px', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        position: 'relative'
    },
    dialogButtonsParent: {
        display: 'flex', 
        justifyContent: 'center',
        marginBottom: '10px',
        marginTop: '-10px'
    },
    closeIconButton: {
        position: 'absolute',
        right: '7px'
    },
    acceptChatButton: {
        backgroundColor: '#01295F', 
        color: '#fff', 
        padding: '5px 15px', 
        borderRadius: '5px'
    },
    rejectChatButton: {
        color: '#01295F', 
        marginLeft: '16px', 
        padding: '5px 15px', 
        borderRadius: '5px', 
        border: '1px solid #01295F'
    }
}))

export default classes