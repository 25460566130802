import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { PAGE_SIZE } from "../../utils/variable";
import Pagination from "@mui/material/Pagination";
import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: '100%',
    [theme.breakpoints.up("sm")]: {
    },
    [theme.breakpoints.up("md")]: {
    },
    [theme.breakpoints.up("lg")]: {
    },
    [theme.breakpoints.up("xl")]: {
    },
  },
  table: {
    borderRadius:'15px',
    maxWidth: 'auto',
    // margin: '20px',
    position: 'relative',
    backgroundColor: '#ffffff',
    boxShadow: '9px 9px 50px rgba(0, 0, 0, 0.12)',
    "&": {
      maxWidth: 'auto',
    },
    "& thead th": {
      fontWeight: "600",
      fontSize: "15px",
      color: "#ffffff",
      lineHeight: "22.5px",
      backgroundColor: "#292660",
      // backgroundColor: 'linear-gradient(to right, #9B92B7, #D6949F)',
      textAlign: 'center',
    },
    "& thead th:first-child": {
      borderRadius: "15px 0 0"
    },
    "& thead th:last-child": {
      borderRadius: "0 15px 0 0"
    },
    "& tbody td": {
      fontWeight: "500",
      textAlign: 'center',
    },
    
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
    },
    "& tbody tr:last-child td": {
      borderBottom:'none'
    },
  },
  pageNtn: {
    margin: theme.spacing(2.5),
    marginTop: theme.spacing(5),
    maxWidth: '26vw',
    position: 'relative',
    boxShadow: '9px 9px 50px rgba(0, 0, 0, 0.12)',
    // backgroundColor: 'red',
  }
}));

export default function useTable(records, headCells, totalCount) {
  const classes = useStyles();
  const [pageNo, setPageNo] = useState(1);
  const [slNo, setSlNo] = useState(0);

  const handleChange = (event, value) => {
    setPageNo(value);
    setSlNo(value - 1);
  };

  const TblContainer = (props) => (
    <Table className={classes.table}>{props.children}</Table>
  );

  const TblHead = (props) => (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.label}>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
  const TblPagination = () => (
    <Pagination
      className={classes.pageNtn}
      count={Math.ceil(totalCount / PAGE_SIZE)}
      page={pageNo}
      onChange={handleChange}
    />
  );
  return {
    TblContainer,
    TblHead,
    TblPagination,
    pageNo,
    slNo,
  };
}
