import React from 'react'
import { Routes, Route } from 'react-router-dom';
import AuthLayout from './Layout/Auth';
import './App.css'
import AdminLayout from './Layout/Admin';
import Login from './components/Login/Login';
import Privacypolicy from './components/Privacypolicy/Privacypolicy';
import PrivacyPolicyUpdated from './components/Privacypolicy/PrivacyPolicyUpdated';
import ProtectedRoutes from './components/Sharedfeatures/ProtectedRoutes';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ForgotPassword/ResetPassword';
import ScrollToTop from './components/common/ScrollToTop';
import { CssBaseline } from '@mui/material';
import LogRedirect from './components/Sharedfeatures/LogRedirect';
import PageLayout from './Layout/PageLayout';

const App = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path='/admin/*' element={<ProtectedRoutes><PageLayout /></ProtectedRoutes>} />
        <Route path="/" element={<LogRedirect />} />
        {/* <Route path="/privacy_policy" element={<Privacypolicy />} />
        <Route path="/privacypolicy" element={<PrivacyPolicyUpdated />} /> */}
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword/:id" element={<ResetPassword />} />
      </Routes>
      <CssBaseline />
    </>
  )
}

export default App

