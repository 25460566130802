import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DataService from '../services/DataService'

export const getCustomersList = createAsyncThunk(
    'customers-list',
    async (obj) => {
        const {pageNum, searchQry, startDate, endDate} = obj
        const url =`admin/allusers/?keyword=${searchQry}&page=${pageNum}&limit=10&fromDate=${startDate}&toDate=${endDate}`
        const res = await DataService.get(url)
        return res.data;
    }
)

const initialState = {
    isLoading: true,
    customersList: [],
    totalCount: ''
}

const customersListSlice = createSlice({
    name: 'customers list',
    initialState,
    extraReducers: {
        [getCustomersList.pending]: (state) => {
            state.isLoading = true;
        },
        [getCustomersList.fulfilled]: (state, action) => {          
            state.customersList = action.payload.users
            state.totalCount = action.payload.dataCount
            state.isLoading = false;
        },
        [getCustomersList.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.error.message
        },
    }
})

export default customersListSlice.reducer