import loanProductsActiveIcon from '../assets/images/menu-icons/loan-products-active.svg'
import loanProductsInactiveIcon from '../assets/images/menu-icons/loan-products-inactive.svg'
import loanApplicationsActiveIcon from '../assets/images/menu-icons/loan-applications-active.svg'
import loanApplicationsInactiveIcon from '../assets/images/menu-icons/loan-applications-inactive.svg'
import loansDisbursedActiveIcon from '../assets/images/menu-icons/loans-disbursed-active.svg'
import loansDisbursedInactiveIcon from '../assets/images/menu-icons/loans-disbursed-inactive.svg'
import loanDefaultedActiveIcon from '../assets/images/menu-icons/loan-defaulted-active.svg'
import loanDefaultedInactiveIcon from '../assets/images/menu-icons/loan-defaulted-inactive.svg'
import latestRepaymentsActiveIcon from '../assets/images/menu-icons/latest-repayments-active.svg'
import latestRepaymentsInactiveIcon from '../assets/images/menu-icons/latest-repayments-inactive.svg'
import customersActiveIcon from '../assets/images/menu-icons/customers-active.svg'
import customersInactiveIcon from '../assets/images/menu-icons/customers-inactive.svg'
import Repayment from '../components/Repayment/Repayment'
import CustomersList from '../components/Customer/CustomersList'
import LoanApplication from '../components/LoanApplication/LoanApplication';
import RejectedList from '../components/RejectedApplications/RejectedList';
import LoanDisbursed from '../components/LoanDisbursed/LoanDisbursed';
import CustomerSupport from '../components/Support/CustomerSupport';

const routes = [
    {
      collapse: false,
      path: "*",
      layout: "/admin",
      state: "Error",
      key: "error",
      show: false,
      component: Error,
    },
    {
        collapse: false,
        path: "customers",
        layout: "/admin",
        name: 'Customers',
        menuiconActive: customersActiveIcon,
        menuicon: customersInactiveIcon,
        show: true,
        component: CustomersList
    },
    {
        collapse: false,
        path: "loanapplication",
        layout: "/admin",
        name: 'Loan Application',
        menuiconActive: loanApplicationsActiveIcon,
        menuicon: loanApplicationsInactiveIcon,
        show: true,
        component: LoanApplication,
    },
    {
        collapse: false,
        path: "rejected",
        layout: "/admin",
        name: 'Rejected Applications',
        menuiconActive: loanDefaultedActiveIcon,
        menuicon: loanDefaultedInactiveIcon,
        show: true,
        component: RejectedList
    },
    {
        collapse: false,
        path: "loandisbursed",
        layout: "/admin",
        name: 'Loans Disbursed',
        menuiconActive: loansDisbursedActiveIcon,
        menuicon: loansDisbursedInactiveIcon,
        show: true,
        component: LoanDisbursed,
    },
    {
        collapse: false,
        path: "repayment",
        layout: "/admin",
        name: 'Latest Repayments',
        menuiconActive: latestRepaymentsActiveIcon,
        menuicon: latestRepaymentsInactiveIcon,
        show: true,
        component: Repayment,
    },
    // {
    //     collapse: false,
    //     path: "customersupport",
    //     layout: "/admin",
    //     name: 'Customer Support',
    //     menuiconActive: loanProductsActiveIcon,
    //     menuicon: loanProductsInactiveIcon,
    //     show: true,
    //     component: CustomerSupport
    // },    
];

export default routes