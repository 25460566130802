import Login from './components/Login/Login';
import Logout from './components/Login/Logout';
import LoanApplication from './components/LoanApplication/LoanApplication';
import LoanDisbursed from './components/LoanDisbursed/LoanDisbursed';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import PaymentIcon from '@mui/icons-material/Payment';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupIcon from '@mui/icons-material/Group';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { 
  TXT_LOAN_APPLICATION, 
  TXT_LOAN_DISBURSED, 
  TXT_REPAYMENT, 
  TXT_CUSTOMER_SUPPORT,
  TXT_REJ_APPLN,
  TXT_CUSTOMER
 } from './utils/header';
import Repayment from './components/Repayment/Repayment';
import RejectedList from './components/RejectedApplications/RejectedList';
import CustomersList from './components/Customer/CustomersList';
import CustomerSupport from './components/Support/CustomerSupport';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import Error from './components/Error/Error';
import PageNotFound from './components/PageNotFound/PageNotFound';


var routes = [
  {
    collapse: false,
    path: "*",
    layout: "/admin",
    state: "Error",
    key: "error",
    show: false,
    component: Error,
  },
  {
    collapse: true,
    path: "/customers",
    layout: "/admin",
    name: `${TXT_CUSTOMER}`,
    state: "customers",
    key: "customers",
    show: false,
    icon: <GroupIcon fontSize= 'large' sx={{ color: '#01295F' }} />,
    component: CustomersList
  },
  {
    collapse: true,
    path: "/loanapplication",
    layout: "/admin",
    name: `${TXT_LOAN_APPLICATION}`,
    state: "LoanApplication",
    key: "loanApplication",
    show: true,
    icon: <GroupWorkIcon fontSize= 'large' sx={{ color: '#01295F' }} />,
    component: LoanApplication,
  },
  {
    collapse: true,
    path: "/rejected",
    layout: "/admin",
    name: `${TXT_REJ_APPLN}`,
    state: "rejected",
    key: "rejected",
    show: false,
    icon: <CancelScheduleSendIcon fontSize= 'large' sx={{ color: '#01295F' }} />,
    component: RejectedList
  },
  {
    collapse: true,
    path: "/loandisbursed",
    layout: "/admin",
    name: `${TXT_LOAN_DISBURSED}`,
    state: "loan disbursed",
    key: "loandisbursed",
    show: true,
    icon: <PaymentIcon fontSize= 'large' sx={{ color: '#01295F' }} />,
    component: LoanDisbursed,
  },
  {
    collapse: true,
    path: "/repayment",
    layout: "/admin",
    name: `${TXT_REPAYMENT}`,
    state: "repayment",
    show: true,
    icon: <CurrencyExchangeIcon fontSize= 'large' sx={{ color: '#01295F' }} />,
    component: Repayment,
  },
  {
    collapse: true,
    path: "/customersupport",
    layout: "/admin",
    name: `${TXT_CUSTOMER_SUPPORT}`,
    state: "customer support",
    key: "customersupport",
    show: false,
    icon: <SupportAgentIcon fontSize= 'large' sx={{ color: '#01295F' }} />,
    component: CustomerSupport
  },
  {
    collapse: false,
    path: "*",
    layout: "/auth",
    state: "pagenotfound",
    key: "pagenotfound",
    show: false,
    component: PageNotFound,
  },
  {
    collapse: false,
    path: "/logout",
    layout: "/auth",
    name: "Logout",
    state: "logout",
    key: "logout",
    show: false,
    icon: <LogoutIcon fontSize= 'large' sx={{ color: '#01295F' }} />,
    component: Logout,
  },
  {
    collapse: false,
    path: "/login",
    layout: "/auth",
    name: "Login",
    state: "login",
    key: "login",
    show: false,
    component: Login
  },
  ];

export default routes;
