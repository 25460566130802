import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from '../services/DataService'

export const forgotPassword = createAsyncThunk(
    'forgotPassword',
    async (data, thunkAPI) => {
        try {
            const url = `admin/forgot-password`
            const res = await DataService.forgotPassword(url, data);
            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const resetPassword = createAsyncThunk(
    'resetPassword',
    async (params_data, thunkAPI) => {
        const {id, values: {newPassword}} = params_data
        const data = {
            newPassword
        }
        try {
            const url = `admin/reset-password/${id}`
            const res = await DataService.forgotPassword(url, data);
            return res.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

const initialState = {
    message: "",
    success: ""
}

const forgotPasswordSlice = createSlice({
    name: "forgot-password",
    initialState,
    extraReducers: {
        [forgotPassword.fulfilled]: (state, action) => {
            state.message = action.payload.message;
            state.success = action.payload.success;
        },
        [forgotPassword.rejected]: (state, action) => {
            state.message = action.payload.message;
            state.success = action.payload.success;
        },
        [resetPassword.fulfilled]: (state, action) => {
            state.message = action.payload.message;
            state.success = action.payload.success;
            localStorage.clear();
        },
        [resetPassword.rejected]: (state, action) => {
            state.message = action.payload.message;
            state.success = action.payload.success;
        }
    }
})

export default forgotPasswordSlice.reducer