import React from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Grid, IconButton } from '@mui/material';
import { AiOutlineClose } from 'react-icons/ai';
import Button from '../../controls/Button';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: '#fff',
    boxShadow: 24,
    borderRadius: '15px',
};

const IncomeVerificationModal = ({ open, handleClose, data }) => {

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Box className='incomeVerificationHeaderParent'>
                        <h3 className="incomeVerificationHeading">Income Details</h3>
                        <IconButton onClick={handleClose} className="incomeVerificationClose">
                            <AiOutlineClose />
                        </IconButton>
                    </Box>

                    <Box className='incomeDetailsParent'>
                        <Grid container spacing={2}>
                            <Grid item md={8}>
                                <h3>
                                    Salary
                                </h3>
                            </Grid>
                            <Grid item md={4}>
                                <h3>
                                    {data && data.salary}
                                </h3>
                            </Grid>

                            <Grid item md={8}>
                                <h3>
                                    Monthly Bank Balance
                                </h3>
                            </Grid>
                            <Grid item md={4}>
                                <h3>
                                    {data && data.avgMinBankBalance}
                                </h3>
                            </Grid>

                            <Grid item md={8}>
                                <h3>
                                    Number of cheque bounces
                                </h3>
                            </Grid>
                            <Grid item md={4}>
                                <h3>
                                    {data && data.totalBounce}
                                </h3>
                            </Grid>

                            <Grid item md={8}>
                                <h3>
                                    Total credit card payment
                                </h3>
                            </Grid>
                            <Grid item md={4}>
                                <h3>
                                    {data && data.creditCard}
                                </h3>
                            </Grid>

                            <Grid item md={8}>
                                <h3>
                                    Total loan payments
                                </h3>
                            </Grid>
                            <Grid item md={4}>
                                <h3>
                                    {data && data.emi}
                                </h3>
                            </Grid>
                        </Grid>

                        <Box className="incomeVerificationOkParent" sx={{ mt: 2 }}>
                            <Button text="Ok" className='incomeDetailsButton' onClick={handleClose} />
                        </Box>
                    </Box>

                </Box>
            </Modal>
        </>
    )
}

export default IncomeVerificationModal