const loanApplicationHead = [
    { id: 'slno', label: '#' },
    { id: 'customername', label: 'Name' },
    { id: 'applicationno', label: 'Application No' },
    { id: 'phone', label: 'Phone' },
    // { id: 'email', label: 'Email' },
    { id: 'companyname', label: 'Company Name' },
    { id: 'date', label: 'Date' },
    { id: 'amount', label: 'Amount' },
    { id: 'action', label: 'Action' },
]

const loanDisbursedHead = [
    { id: 'slno', label: '#' },
    { id: 'ac_no', label: 'Loan A/C No' },
    { id: 'user_name', label: 'Name' },
    { id: 'tenure', label: 'Tenure' },
    { id: 'loan_disb', label: 'Amount' },
    { id: 'int_rate', label: 'Interest Rate' },
    { id: 'disbursed_date', label: 'Disbursed Date' },
    { id: "disbursed_details", label: "Disbursed Details" },
]

const repaymentHead = [
    { id: "slno", label: "#" },
    { id: "appln_no", label: "Application No" },
    { id: "name", label: "Name" },
    { id: "amount", label: "Repayed Amount" },
    { id: "principal_portion", label: "Principal" },
    { id: "outstanding_balance", label: "Balance" },
    { id: "transaction_date", label: "Transaction Date" },
    { id: "repayment_expected", label: "Expected Repayment" }
]

const rejectedApplnHead = [
    { id: "slno", label: "#" },
    { id: 'appln_no', label: 'Application No' },
    { id: "applied_name", label: "Name" },
    { id: "applied_date", label: "Applied Date" },
    { id: "rej_date", label: "Rejected Date" },
    { id: "rej_reason", label: "Rejected Reason" },
]

const customersHead = [
    { id: "slno", label: "#" },
    { id: "customer_name", label: "Customer Name" },
    { id: "age", label: "Age" },
    { id: "salary", label: "Salary" },
    { id: "loan_acc_no", label: "Acc No" },
    { id: "total_loan", label: "Total Loan" },
    // { id: "active_loan", label: "Active Loan" },
    { id: "created_at_date", label: "Date" },
]

const customerSupport = [
    { id: "slno", label: "#" },
    { id: "token_id", label: "Token Id" },
    { id: "username", label: "Username" },
    { id: "support_for", label: "Support For" },
    { id: "details", label: "Details" },
    { id: "status", label: "Status" },
    { id: "date", label: "Date" },
    { id: "action", label: "Action" },
]


const HeadCells = {
    loanApplicationHead,
    loanDisbursedHead,
    repaymentHead,
    rejectedApplnHead,
    customersHead,
    customerSupport
}

export default HeadCells;