import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DataService from '../services/DataService'

export const getRepaymentsList = createAsyncThunk(
    'repayments-list',
    async ({ searchQry, pageNum }) => {
        const url = `admin/latest-repayment?keyword=${searchQry}&page=${pageNum}&limit=10`;
        const res = await DataService.get(url)
        // console.log("RESPONSE", res)
        return res.data;
    }
)

export const viewRepaymentListDetails = createAsyncThunk(
    "viewrepaymentdetails",
    async (applicNo, thunkAPI) => {
        try {
            // const url = `admin/repaymentd-loans/${applicNo}`
            // const res = await DataService.post(url);
            // return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
)


const initialState = {
    repaymentsList: [],
    repaymentDetails: {},
    totalCount: '',
    isLoading: true
}

const repaymentsListSlice = createSlice({
    name: 'repayment list',
    initialState,
    extraReducers: {
        [getRepaymentsList.pending]: (state, action) => {
            state.isLoading = true
        },
        [getRepaymentsList.fulfilled]: (state, action) => {
            console.log("ACT", action)
            state.repaymentsList = action.payload.data
            state.totalCount = action.payload.dataCount
            state.isLoading = false
        },
        [getRepaymentsList.rejected]: (state) => {
            state.isLoading = false
        },
        [viewRepaymentListDetails.fulfilled]: (state, action) => {
            console.log("ACT", action)
            state.repaymentDetails = action.payload.responseData
          }
        },
        reducers: {
          resetRepaymentDetails: (state) => {
            state.repaymentDetails = {};
          }
        }
})

export const { resetRepaymentDetails } = repaymentsListSlice.actions;
export default repaymentsListSlice.reducer